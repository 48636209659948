import React from "react";
import {inject, observer} from "mobx-react";
import {renderMarkdownWithInterpolation} from "../../../markdown/markdown-factory";
import {TEMPLATE_CONSTANTS} from "../../../markdown";
import {TDL_Default} from "../../../markdown/token-details/templates";
import MoleculeImage from "../../MoleculeImage";
import {
  MDCollapsibleList,
  MDCollapsibleText
} from "../../results-grid/grid-row/MDCollapsibleContent";

import {markMatchingText} from "../../../markdown/utils";

const TokenItemDetail = ({
  entity,
  onItemDelete,
  searchSessionStore: { templates } = {}
}) => (
  <div className={`token-details-body${entity.ambiguous ? ' alert-warning' : ''}`}>
    {renderMarkdownWithInterpolation({
                                       templateType: TEMPLATE_CONSTANTS.TOKEN_DETAILS,
                                       templates,
                                       templateOverrides: {
                                         SMILES: {
                                           component: MoleculeImage,
                                           props: {encoding: entity.smiles || entity.inchi},
                                         },
                                         Text: {
                                           component: MDCollapsibleText
                                         },
                                         List: {
                                           component: MDCollapsibleList
                                         }
                                       },
                                       entity,
                                       markText: entity['match-term'] ? text => markMatchingText(text, entity['match-term']) : null,
                                       fallback: TDL_Default})}
    <span
      className="delete-search-term"
      aria-hidden="true"
      onClick={x => onItemDelete(entity.id)}
    >
      ×
    </span>
  </div>
);

export default inject("searchSessionStore")(observer(TokenItemDetail));

import React, {Component, Profiler} from "react";
import {generateExternalLinks} from "./links";
import {toast} from "react-toastify";
import {interpolateEntityFields} from "../markdown/interpolation";
import {DEV, getEnv} from "./configMgr";

//const UNICODE_PUNCTUATION_RE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%()*+,\-./:;=?@[\]^_`{|}~]/g;
export const RE_CATEGORY_PREFIXED_ID = /^(?!https?:)[a-z][-a-z]+:[a-z][-a-z]+:.*$/;
export const RE_PLEX_ID = /^(?!https?:)[a-z][-_0-9a-z]+:.*$/;

export const DELETE_MARKER = "ⓧ";
export const NBSP = "\u00A0";

export const elideString = (str, len, end = "...") => {
  return str.length >= len + end.length ? str.substring(0, len) + end : str;
};

const PREFERRED_ALIAS_FORMATS = [
  // Single words, no digits
  /^[^\d\W]+$/,
  // with dashes (no inchi)
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[^\d\W]([^\d\W]|-)+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)([^\d\W]|-)+$/,
  // with spaces or commas
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[^\d\W]([^\d\W]|[-\s,])+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)([^\d\W]|[-\s,])+$/,
  // with parens/brackets/plus
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[^\d\W]([^\d\W]|[-+\s,()\][])+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)([^\d\W]|[-+\s,()\][])+$/,
  // with numbers
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)\D[-\w+\s,()\][]+$/,
  /^(?![A-Z]{14}-[A-Z]{10}-[A-Z]|S?CHEMBL|CID|UCI)[-\w+\s,()\][]+$/,
  // preferred datasets if no other matches
  // /^(CHEMBL\d+)$/,
  /^(CHEMBL|CID)\d+$/,
  // anything else
  /^(.*)$/,
]

export const countNonwordChars = s => {
  return s.replace(/([^\d\W]|[-\s])/g, "").length;
};

export const countDigits = s => {
  return s.replace(/\D/g, "").length;
};

export const getBestName = (entity, short = false) => {
  if (!entity) {
    return "<null>";
  }
  const noTitle = getEnv() === "dev" ? " (no title)" : "";
  if (entity.entity) {
    entity = entity.entity;
  }
  if (entity.label) {
    if (short && entity.category === 'target' && entity.gene_symbol) {
      return entity.gene_symbol;
    }
    return entity.label;
  }
  if (entity.title) {
    return entity.title;
  }
  if (entity.category === 'target' && entity.gene_symbol) {
    return `${entity.gene_symbol}${noTitle}`;
  }
  const aliases = (entity.aliases || []).slice();
  if (aliases.length > 0) {
    for (let fmt of PREFERRED_ALIAS_FORMATS) {
      const candidates = []
      aliases.forEach(alias => {
        if (alias.match(fmt)) {
          candidates.push(alias);
        }
      });
      if (candidates.length > 0) {
        const sorted = candidates.sort((a, b) => {
          // Prefer CHEMBL > CID > SCHEMBL/BDB/HMDB > UCI
          if (/^(CHEMBL|CID)/i.test(a) && /^(CHEMBL|CID)/i.test(b)) {
            return a - b;
          }
          const delta = countNonwordChars(a) - countNonwordChars(b);
          const delta2 = countDigits(a) - countDigits(b);
          return delta + delta2;
        });
        return `${sorted[0]}${noTitle}`;
      }
    }
  }
  if (entity.iupac) {
    return `${entity.iupac}${noTitle}`;
  }
  return null;
};

export const saveBlob = (filename, blobContent) => {
  try {
    const blob = new Blob([blobContent], { type: blobContent.type });
    const url = window.URL.createObjectURL(blob);

    // navigator.msSaveOrOpenBlob - available for IE only
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.setAttribute("style", "display: none");
      a.setAttribute("target", "_black");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error(error);
  }
};

export const safeDOMID = id => {
  return id?.replace(/[^-A-Z0-9_]/gi, "-").replace(/-{2,}/, "-");
};

export const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getCategoryKey = (catSrcID) => {
  return RE_CATEGORY_PREFIXED_ID.test(catSrcID) && catSrcID.split(':')[1];
};

export const isNumericID = id => {
  return /^[A-Z]{2,}[-:0-9]{3,}$/i.test(id);
}

export const isInchi = id => {
  return /^((InChI=)[^J][0-9BCOHNSPrIFla+\-()\\/,pqbtmsih]{6,})$/i.test(id);
}

export const isInchiKey = id => {
  return /^[A-Z]{{14}}-[A-Z]{{10}}-[A-Z]?/.test(id);
}

export const isPlexID = id => {
  return RE_PLEX_ID.test(id) && !RE_CATEGORY_PREFIXED_ID.test(id);
}

export const getExternalID = id => {
  return (RE_CATEGORY_PREFIXED_ID.test(id) && id.slice(id.indexOf(':') + 1)) || id;
}

export const extractLabel = term => {
  if (term.indexOf("label=") !== -1) {
    return [term.replace(/\s+label=.*$/, ""), term.substring(term.indexOf("label=") + "label=".length).trim()];
  }
  return [term , null];
};

export const saveCustomLabel = (id, label) => {
  try {
    const key = `label-${id}`;
    if (label) {
      localStorage[key] = label.trim();
    } else {
      localStorage.removeItem(key);
    }
  }
  catch(e) {
    console.error(`Could not access local storage ${e}`);
  }
};

export const getCustomLabel = id => {
  try {
    return localStorage[`label-${id}`]?.trim();
  }
  catch(e) {
    console.error(`Could not access local storage ${e}`);
  }
};

export const patchEntity = (entity, categories, dataset, templates, preferredNames={}, datasets = null) => {
  if (entity['_patched']) {
    return entity;
  }
  entity['_patched'] = true;
  if (entity.tags && entity.tags[0] !== entity.category) {
    entity.baseCategory = entity.category;
    entity.category = entity.tags[0] || entity.category;
  }
  else {
    entity.baseCategory = entity.category;
  }
  const cat = categories[entity.category];
  if (!cat && entity.category !== 'archived' && Object.keys(categories).length > 0) {
    console.error(`No category corresponding to '${entity.category}':`, entity, categories);
  }
  // Singular
  entity.categoryName = (cat && cat.name) || entity.category;
  // Plural (e.g. tab name)
  entity.categoryLabel = (cat && cat.tab_name) || entity.categoryName;

  if (typeof (entity.aliases) === 'string') {
    entity.aliases = entity.aliases.split(';')
  }
  // Keep only a single SMILES encoding
  if (entity.smiles && typeof (entity.smiles) !== "string") {
    entity.smiles = entity.smiles[0];
  }

  const prefName = preferredNames[entity.id];
  if (prefName) {
    entity['preferred-name'] = prefName;
  }
  // correct for some GPSA data errors (temporary, remove after data-1.3.1)
  if (entity['direction'] === "down" && entity['id']?.indexOf("UP") !== -1) {
    entity['direction'] = "up";
  }

  const cleanTitle = (t) => {
    return t ? t.replace(/;(?!=\s)/g, "; ")
      .replace(/^\[(.*)]$/, "$1") : t;
  } ;

  // Clean up titles for readability
  entity.title = cleanTitle(entity.title);
  entity.iupac = cleanTitle(entity.iupac);
  entity['best-alias'] = cleanTitle(getBestName(entity));
  // Decode and interpolate properties
  entity = interpolateEntityFields(templates, entity, dataset);

  // Convert to a list to facilitate display
  if (entity.activity) {
    entity.activity = Object.entries(entity.activity);
  }

  if (datasets) {
    entity.links = generateExternalLinks(entity, datasets);
  }

  Object.entries(entity).forEach(([k, v]) => {
    if (isTruthy(v)) {
      if (!entity.flags) {
        entity.flags = [k];
      }
      else {
        entity.flags.push(k);
      }
    }
  });
  ["description", "abstract"].forEach(k => {
    if (entity[k]) {
      entity[k] = entity[k].replace(/(<br\/>\s*)+/gi, "\n").trim();
    }
  });
  const customLabel = getCustomLabel(entity.id);
  if (customLabel) {
    if (!entity.label) {
      entity.label = customLabel;
    }
    else if (entity.label.indexOf(customLabel) === -1) {
      entity.label = `[${customLabel}] ${entity.label}`;
    }
  }
  return entity;
};

export const withProfiler = (WrappedComponent) => (props) => (<Profiler><WrappedComponent {...props} /></Profiler>);

// Wrap a component in this to debug when properties change
export function withPropsChecker(WrappedComponent, defaultName= "?") {
  return class PropsChecker extends Component {
    componentDidUpdate(lastProps) {
      Object.keys(lastProps)
        .filter(key => {
          return lastProps[key] !== this.props[key];
        })
        .forEach(key => {
          const name = WrappedComponent.displayName || WrappedComponent.name || defaultName;
          console.debug(`changed property "${key}" on ${name} from`, lastProps[key], 'to', this.props[key]);
        });
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export const copyToClipboard = (text) => {
  const dataTypes = {
    "text/plain": new Blob([text], {type: 'text/plain'}),
  };
  if (text.indexOf("\t") !== -1) {
    // Not all systems support this and you don't find out until clipboard write
    //dataTypes["text/tab-separated-values"] = new Blob([text], {type: 'text/tab-separated-values'});
  }
  const item = new ClipboardItem(dataTypes);
  navigator?.clipboard?.write([item]).then(() => {})
    .then(() => {})
    .catch((error) => {
      toast.error(`Clipboard write is unavailable (${error})`);
    });
}

export const naturalSort = (a, b) => {
  if (!a) {
    return !b ? 0 : 1;
  }
  return a.localeCompare(b, undefined, {numeric: true, ignorePunctuation: true, sensitivity: "base"});
};

export const RE_PREFIXED = /^([^:]+):.*$/i;
export const RE_INT_ID = /^[^:]+:(\d+)$/i;
export const RE_DATASET_ID = /^([-a-z0-9]+):(.*)$/;

export const splitID = id => [datasetPrefix(id), getExternalID(id)];

export const stripDataset = id => RE_DATASET_ID.test(id) ? id.replace(RE_DATASET_ID, '$2') : id;

export const datasetPrefix = id => RE_PREFIXED.test(id) && id.replace(RE_PREFIXED, '$1');

export const isInteger = (s) => {
  return Number.isInteger(+(typeof s === 'string' ? s.trim() : s));
};

export const formatMultipleItemTitle = (items, maxItems = null) => {
  if (!maxItems) {
    maxItems = 3;
  }
  const names = items.map(x => typeof(x) === "string" ? x : getBestName(x, maxItems != null))
  if (items.length > maxItems + 1) {
    const explicit = names.slice(0, maxItems);
    return `${explicit.join(", ")} and ${items.length - explicit.length} others`;
  }
  return `${names.join(", ")}`;
}

// TODO: move title setting to Helmet (once helmet errors get fixed)
export const setPageTitle = (basis = "Plex Search", maxItems = 3) => {
  if (typeof(basis) === "string") {
    document.title = basis;
  }
  else if (basis?.length) {
    document.title = formatMultipleItemTitle(basis, maxItems)
  }
  else {
    document.title = "Plex Search";
  }
};

const TRUTHY = new Set(["y", "yes", "true", "t"]);

export const isTruthy = (s) => {
  return s === true || (typeof(s) === "string" && TRUTHY.has(s.toLowerCase()));
}

export const isImage = file => {
  return /^image\//.test(file.type);
};
export const fileKey = file => {
  return `${file.name}:${file.size}:${file.lastModified}`;
};
export const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

export const xhrUpload = async (url, file, onProgress = (uploadState) => {
}, authData = null) => {
  const state = {file, progress: 0, active: true};
  onProgress(state);
  return new Promise((resolve, reject) => {
    if (DEV && url.startsWith("/")) {
      url = `http://localhost:5000${url}`;
    }
    const formData = new FormData();
    formData.append('file', file);
    const xhr = new XMLHttpRequest();
    state.xhr = xhr;
    xhr.open("POST", url, true);
    Object.entries(authData.headers || {}).forEach(([k, v]) => {
      xhr.setRequestHeader(`${k}`, `${v}`);
    })

    const getResponse = xhr => {
      const raw = xhr.response;
      if (xhr.responseType === "" && typeof (raw) === "string" && (raw[0] === "{" || raw === "null")) {
        return ["json", JSON.parse(raw)];
      }
      return [xhr.responseType, raw];
    };

    onProgress(state);
    xhr.upload.onprogress = e => {
      state.event = e;
      if (e.lengthComputable) {
        state.progress = Math.floor(e.loaded * 100 / e.total);
      } else {
        state.progress += (e.total - state.progress) / 2;
      }
      onProgress(state);
    };
    xhr.onerror = (e) => {
      state.event = e;
      state.error = e;
      state.status = xhr.status;
      //console.log("xhr.onerror");
      reject(state);
    };
    xhr.ontimeout = (e) => {
      state.event = e;
      state.error = e;
      state.timeout = true;
      state.status = xhr.status;
      //console.log("xhr.ontimeout");
      reject(state);
    };
    xhr.onabort = (e) => {
      state.event = e;
      state.abort = true;
      //console.log("xhr.onabort");
      reject(state);
    };
    xhr.onload = e => {
      const [responseType, response] = getResponse(xhr);
      state.event = e;
      state.status = xhr.status;
      state.active = false;
      //console.log("xhr.onload", xhr, state);
      onProgress(state);
      if (xhr.readyState === xhr.DONE) {
        state.response = response;
        if (xhr.status && xhr.status < 400 && (responseType !== "json" || !response.status || response.status < 400)) {
          //console.log("xhr complete", xhr, state);
          resolve(state)
        } else {
          state.error = e;
          //console.log("xhr.onload(error)", xhr, state);
          reject(state);
        }
      }
    }
    xhr.send(formData);
  });
}

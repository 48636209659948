import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import CollapsibleText from "./CollapsibleText";
import CollapsibleList from "./CollapsibleList";
import _ from "lodash";
import {hasMark} from "../../markdown/utils";

const Collapsible = ({
    onToggle = () => {},
    type = "text",
    item = "",
    isOpen: initialIsOpen = false,
    visibleNum = 160,
    minimumHidden = 80,
    markText = null,
    abbreviate = true,
    openWhenMarked = true,
  }) => {

  const listRef = useRef();
  const [isOpen, setIsOpen] = useState(!!initialIsOpen);
  const [forceOpen, setForceOpen] = useState(false);

  useEffect(() => {
    onToggle(isOpen);
  }, [isOpen]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    setForceOpen(markText && openWhenMarked && hasMark(listRef.current));
  }, [markText, openWhenMarked]);

  if (type === "list" || item instanceof Array) {
    const value = item instanceof Array ? item : (item && [item]) || []
    // Force open if the list contains the given marker
    return (
      <CollapsibleList
        collapsingListRef={listRef}
        isOpen={isOpen || forceOpen}
        list={value}
        onToggle={forceOpen ? null : handleToggle}
        visibleNum={visibleNum}
        minimumHidden={minimumHidden}
        markText={markText}
        abbreviate={abbreviate}
      />
    );
  } else if (type === "text") {
    return (
      <CollapsibleText
        isOpen={isOpen}
        text={item || ""}
        onToggle={handleToggle}
        visibleChars={visibleNum}
        minimumHidden={minimumHidden}
        markText={markText}
      />
    );
  } else {
    return <span>{`Unknown collapsible type '${type}'`}</span>;
  }
};

Collapsible.propTypes = {
  type: PropTypes.oneOf(["text", "list"]),
  item: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  visibleNum: PropTypes.number,
  abbreviate: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default Collapsible;

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Dropdown, DropdownButton} from "react-bootstrap";
import OutboundLink from "../../OutboundLink";
import _ from "lodash";
import {naturalSort} from "../../../lib/utils";


// "Green" links
const ExternalLinks = ({links = []}) => {

  if (links.length === 0) {
    return null;
  }
  const [groupedLinks, setGroupedLinks] = useState({});
  useEffect(() => {
    setGroupedLinks(_.groupBy(links.filter(x => x.title && x.url), "title"));
  }, [links]);

  const linksList = Object.keys(groupedLinks).sort().map(groupName => {
    const linkGroup = groupedLinks[groupName];
    if (linkGroup.length === 1) {
      return linkGroup.map((link) => {
        const url = link.url;
        const handleClick = e => e;
        return (
          <OutboundLink
            key={groupName}
            className="source-link"
            title={link.description}
            href={url}
            onClick={handleClick}
          >
            {link.title}
          </OutboundLink>
        );
      });
    } else {
      // dropdown link set
      linkGroup.sort((a, b) => {
        return naturalSort(a.externalID, b.externalID);
      });
      return (
        <DropdownButton
          key={groupName}
          title={groupName}
          className="grouped-source-links source-link"
        >
          {linkGroup.map(({ url, title, externalID }) => (
            <Dropdown.Item key={externalID} href={url} target="_blank" title={title}>
              {externalID}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      );
    }
  });
  return (<div className={"source-links"}>{linksList}</div>);
};

ExternalLinks.propTypes = {
  links: PropTypes.array.isRequired,
};

const _MDExternalLinks = (props) => {
  const {entity = {}, links = []} = props;
  return (<ExternalLinks links={!links ? [] : links} entity={entity} />);
}

export const MDExternalLinks = _MDExternalLinks;

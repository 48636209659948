import React, {useCallback, useState} from "react";
import {renderMarkdown} from "../../markdown/markdown-factory";
import {toast} from "react-toastify";

import {Button, Col, Row} from "react-bootstrap";
import ObjectEditor from "./ObjectEditor";
import TemplateEditor from "./TemplateEditor";
import ExampleSelector from "./ExamplesPanel";

import "./styles.css";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {markMatchingText} from "../../markdown/utils";

const Sandbox = ({
  key = "",
  samples = [],
  overrides = {},
  additionalSamples = [],
}) => {
  return ({fetchEntities}) => {
    const interpolate= key !== "";
    const defaultTemplate = samples.length > 0 ? samples[0].template : "";
    const defaultEntity = samples.length > 0 ? samples[0].dataObject : {};
    const [entity, setEntity] = useState(interpolate ? JSON.parse(localStorage[`${key}-entity`] || JSON.stringify(defaultEntity)) : null);
    const [template, setTemplate] = useState(localStorage[`${key}-template`] || defaultTemplate);
    const [textFilter, setTextFilter] = useState("");
    const [entityID, setEntityID] = useState(interpolate ? localStorage[`${key}-entity-id`] || "" : "");

    const fetchEntity = (id) => {
      fetchEntities([id]).then(list => {
        if (list && list[0]) {
          setEntity(list[0]);
        }
      })
        .catch(error => toast.error(`Could not fetch data for ${id} (${error})`));
    };

    const reset = () => {
      setEntity(defaultEntity);
      setTemplate(defaultTemplate);
      setTextFilter("");
      delete localStorage[`${key}-template`];
      delete localStorage[`${key}-entity`];
      delete localStorage[`${key}-entity-id`];
    };
    const markText = textFilter ? (input, params) => {
      return markMatchingText(input, textFilter, params);
    } : null;
    const parametersChanged = selectedExample => {
      console.log(`params changed ${selectedExample}`);
      setEntity(selectedExample.dataObject);
      setTemplate(selectedExample.template);
      setTextFilter("");
    };
    const rendered = renderMarkdown({
                                      markdown: template,
                                      entity,
                                      markText,
                                      overrides,
                                      prepare: false,
    });
    const renderedAfterPrepare = renderMarkdown({
                                      markdown: template,
                                      entity,
                                      markText,
                                      overrides,
    });

    return (
      <div className={"sandbox"}>
        <Row className="clear-margin">
          <Col md={key ? 4 : 6}>
            <TemplateEditor
              initText={template}
              onTemplateChange={(x) => {
                setTemplate(x);
                localStorage[`${key}-template`] = x;
              }
            }
            />
          </Col>
          {interpolate && (<Col md={key ? 4 : 6}>
            <ObjectEditor
              key={template}
              data={entity}
              onChange={(editorContent) => {
                console.log("onChange", editorContent);
                //const data = editorContent.text ? JSON.parse(editorContent.text) : editorContent.json;
                // FIXME don't update entity on every edit
                //setEntity(data);
                localStorage[`${key}-entity`] = editorContent.text || JSON.stringify(editorContent.json);
              }}
            />
          </Col>)}
          <Col md={key ? 4 : 6} className="rendered">
            <h4>Compiled w/o prep</h4>
            <div className="result-wrapper result-output result-default">{rendered}</div>
            <h4>Compiled w/prep</h4>
            <div className="result-wrapper result-output result-prepared">{renderedAfterPrepare}</div>
          </Col>
        </Row>
        <Row className="clear-margin">
          <Col md={12}>
            <div className="footer">
              <span className="example-title">Loaded Example:</span>
              <ExampleSelector
                exampleList={samples}
                additionalList={additionalSamples}
                onSelectionChange={parametersChanged}
              />
              <label className="example-title">
                Mark text <input type="text" value={textFilter} className="input-element" onChange={(e) => setTextFilter(e.target.value)}/>
              </label>
              <label>
                <button
                  className="btn btn-primary"
                  disabled={!entityID || entityID.indexOf(":") === -1}
                  onClick={() => fetchEntity(entityID)}>
                  Load by ID
                </button>
                <input type={"text"} value={entityID} onChange={(e) => {
                  setEntityID(e.target.value);
                  localStorage[`${key}-entity-id`] = e.target.value;
                }} className={"input-element"} onPaste={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const pastedText = e.clipboardData.getData("text/plain").trim();
                  console.log(`paste ${pastedText} => ${decodeURIComponent(pastedText)}`);
                  setEntityID(decodeURIComponent(pastedText));
                }}/>
              </label>
              <CopyToClipboardButton className={"float-right btn btn-primary"} getText={() => template}/>
              <Button className="float-right" onClick={reset}>Reset</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default Sandbox;

import React from "react";
import {useNavigate} from "react-router";
import logo from "../../../img/official_logo.png";
import {Message} from "./MessageFactory";
import {ToastContainer} from "react-toastify";

const FormLayout = ({ message = null, children = [], id = "loginForm", className = "login"}) => {
  const navigate = useNavigate();
  return (
    <form
      id={id}
      autoComplete="on"
      action="#"
      className={`${className}`}
      onSubmit={e => e.preventDefault()}
    >
      <div className={`${className}-form`}>
        <div className={`${className}-body`}>
          {className === "login" && (
            <button type="button" className={"help glyphicon glyphicon-question-sign"} onClick={() => navigate("/help")} />
          )}
          <div className={`${className}-img-wrp`}>
            <img src={logo} alt="Plex Research"/>
          </div>
          <div className={"client-logo"}></div>
          {className === "login"
           ? (<div className={`${className}-fields`}>
              <Message message={message}/>
              {children}
            </div>)
           : children}
        </div>
      </div>
      <ToastContainer />
    </form>
  );
}

export default FormLayout;

import React from "react";
import ErrorPage from "../pages/ErrorPage";
import {mapStackTrace} from "sourcemapped-stacktrace";
import _ from "lodash";

const dbMapStackTrace = _.debounce(mapStackTrace, 500);

// NOTE: there is no "componentDidCatch" equivalent for functional components
class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, mappedStackTrace: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Component error", error);
    const stackTrace = error?.stack || errorInfo?.componentStack || "";
    this.setState({
      error: error,
      errorInfo: errorInfo,
      stackTrace
    });
    //dbMapStackTrace(stackTrace, mappedStackTrace => {
    //  this.setState({stackTrace: mappedStackTrace});
    //});
  }

  render() {
    const {error, stackTrace} = this.state;
    if (error) {
      return (
        <div>
          <ErrorPage
            error={error}
            stackTrace={stackTrace}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;

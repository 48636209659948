import OutboundLink from "./OutboundLink";
import SearchContext from "../lib/SearchContext";
import React, {useEffect, useState} from "react";
import {isPlexID} from "../lib/utils";
import {formatMultipleItemTitle} from "../lib/utils";
import _ from "lodash";

export const SubmitSearchButton = ({
  ids = [],
  dsConfig = 0,
  searchParams = {},
  category = null,
  title = "Submit as new search",
  label = "Submit as new search",
  terms = null,
  style = {},
  flat = true,
  disabled = false,
  className = "",
  onSearch = null,
  onClick = null,
  fetchEntities = null,
}) => {
  const [formattedLabel, setFormattedLabel] = useState(label);
  const [resolved, setResolved] = useState(terms == null);
  const localParams = category ? {tab: category} : {};
  const url = SearchContext.getAppSearchURL({searchIDs: ids, dsConfig, ...(searchParams || {})}, localParams, {withLabels: true});
  const href = url?.href || "";

  useEffect(() => {
    if (terms && fetchEntities) {
      const {terms: searchTerms = [], maxItems = 5} = terms;
      const ids = searchTerms.filter(el => isPlexID(el));
      if (!ids.length || resolved === true || _.isEqual(ids, resolved)) {
        //console.log("No term lookup (ids/resolving)", ids, resolved);
      }
      else {
        setResolved(ids);
        setFormattedLabel(formatMultipleItemTitle(searchTerms, maxItems));
        console.debug("fetchEntities", terms, ids);
        fetchEntities(ids)
          .then(result => {
            const map = result.reduce((result, el) => {
              if (el && el.id) {
                result[el.id] = el;
              }
              return result;
            }, {});
            const items = searchTerms.map(el => {
              return map[el] || el;
            });
            console.debug("Resolved search IDs", ids, result, items);
            setResolved(true);
            setFormattedLabel(formatMultipleItemTitle(items, maxItems));
          })
          .catch(() => {
          });
      }
    }
  }, [terms, fetchEntities, resolved])

  if (!onClick) {
    onClick = onSearch ? () => onSearch() : () => {};
  }
  return (
    <OutboundLink
      className={`search-link${flat ? " flat-button" : ""}${className !== "" ? " " + className : ""}`}
      title={title}
      href={href}
      style={style}
      disabled={disabled || (!url && !onSearch)}
      onClick={onClick}
    >
      <span className={`glyphicon glyphicon-search search-icon${disabled ? " disabled" : ""}`} onClick={onClick} />
      {formattedLabel && (
        <span className={disabled ? "disabled" : null} onClick={onClick} >{formattedLabel}</span>
      )}
    </OutboundLink>
  );
};

export default SubmitSearchButton;

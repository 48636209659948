import React from 'react'
import SpinnerAnimation from '../../../components/Spinner'
import Checkbox from "../../../components/controls/Checkbox";
const SignIn = ({
    username, onUsernameChange, onUsernameBlur,
    password, onPasswordChange,
    rememberMe, onRememberMeChange,
    enableSubmit, isRequestProcessing,
    onSignIn,
    onRegisterClick,
    onForgotPass
}) => {
  return (
    <div>
      <p>
        <input
          className    = "login-control-username"
          type         = "text"
          autoComplete = "username"
          placeholder  = "Username/Email"
          value        = { username }
          onChange     = { onUsernameChange }
          onBlur       = { onUsernameBlur }
          disabled     = { isRequestProcessing }
        />
      </p>
      <p>
        <input
          type         = "password"
          className    = "login-control-pass"
          autoComplete = "current-password"
          placeholder  = "Password"
          value        = { password }
          onChange     = { onPasswordChange }
          onKeyDown    = {e => {if (e.code === "Enter") { e.preventDefault(); onSignIn() }}}
          disabled     = { isRequestProcessing }
        />
      </p>
      <p>
        <Checkbox
          checked={rememberMe}
          label={"Remember Me"}
          onChange={onRememberMeChange}
        />
        <button type="button" id={"forgotPass"} className="signup-btn" onClick={onForgotPass}>Forgot Password?</button>
      </p>
      <p>
        <button autoFocus
                type={"submit"}
                inputMode={"submit"}
                className = "login-submit"
                onClick   = { onSignIn }
                disabled  = { !enableSubmit }
        >
          {isRequestProcessing ? 'Authorizing' : "Sign in" }
          <SpinnerAnimation style = {{float: "right", display: isRequestProcessing ? "" : "none"}}/>
        </button>
      </p>
      <p className="signup-block">
        <button
          type = "button"
          className = "signup-btn"
          onClick   = { onRegisterClick }
          title     = {"Click here to register"}
          style     = {{float: "left"}}
        >
          Not registered yet?
        </button>
        <button
          className="signup-btn link"
          title="Send a message to Plex Research"
          style={{float: "right"}}
        >
          <a href={"https://www.plexresearch.com/contact.html"} target={"_blank"}>
            {"Contact Plex"}
          </a>
        </button>
      <br/>
    </p>
</div>
  )
}

export default SignIn;

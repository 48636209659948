import { getConfig } from "../lib/configMgr";
import { toJSON, RequestBuilder } from "./request";
import {TAXID_NONE} from "../stores/AppStatusStore";
import {fetchSearch} from "./search";

const { ExternalRESTAPI } = getConfig();

export const fetchEntities = (ids, dsConfig, auth) => {
  // NOTE: Missing IDs will result in a ServerError
  const url = new URL(ExternalRESTAPI.GetEntities, window.location.origin);
  if (dsConfig !== null) {
    url.searchParams.set('imap', dsConfig || 0);
  }
  const req = new RequestBuilder(url);
  return req
    .asPOST(JSON.stringify(ids))
    .withJSONContentType()
    .withAuthorization(auth)
    .fetch()
    .then(toJSON);
};

export const fetchMembers = (id, dsConfig = null, auth) => {
  const url = new URL(`${ExternalRESTAPI.Members}/${id}`, window.location.origin);
  if (dsConfig !== null) {
    url.searchParams.set('imap', dsConfig || 0)
  }
  return new RequestBuilder(url).withJSONContentType()
      .withAuthorization(auth)
      .fetch()
      .then(toJSON);
}

export const fetchSuggestions = (searchTerms, auth, params = {}) => {
  const {instructions = null, category = null, taxID = null, dsConfig = 0} = params;
  const url = new URL(ExternalRESTAPI.GetSuggestions, window.location.origin);
  // Remove square brackets with adjacent spaces (avoid modifying SMILES strings)
  const terms = typeof searchTerms === "string"
                ? [searchTerms.replace(/( \[ ?| ?] )/g, ' ')]
                : searchTerms;
  if (dsConfig !== null) {
    url.searchParams.set('imap', dsConfig || 0)
  }
  if (category) {
    url.searchParams.set("category", category);
  }
  if (taxID && taxID !== TAXID_NONE && terms.length > 1) {
    url.searchParams.set('tax_id', taxID);
  }
  const req = new RequestBuilder(url);
  const body = {terms}
  if (instructions) {
    body.instructions = instructions;
  }
  // For testing, set this to a desired error condition
  //const cannedResponse = TypeError("Failed to fetch");
  //const cannedResponse = new Response(new Blob([JSON.stringify({})], {type: 'application/json'}),
  //                                   {status: 500, statusText: "Not OK"});
  return (
    req
      .asPOST(JSON.stringify(body))
      .withJSONContentType()
      .withAuthorization(auth)
      .fetch() /* .fetch(0, 5000, cannedResponse */
      .then(toJSON)
  );
};

export const fetchChatCompletion = (searchParams, {messages = [], ...chatParams}, auth) => {
    messages = messages.map(el => ({role: el.role, content: el.content}));
    return fetchSearch({...searchParams, ...chatParams, messages}, auth, {searchType: "chat"});
};

export const fetchHelp = (message, sessionID = null) => {
  const url = new URL(ExternalRESTAPI.Help, window.location.origin);
  url.searchParams.set("message", message);
  if (sessionID) {
    url.searchParams.set("session_id", sessionID);
  }
  const req = new RequestBuilder(url);
  return (req.fetch().then(toJSON));
};

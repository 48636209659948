// Handle LLM tool use

import {fetchChatCompletion, fetchEntities, fetchMembers, fetchSuggestions} from "./queries/autosuggest";
import {DEFAULT_CLIENT_SIM_THRESHOLD} from "./pages/App";

export const TOOL_RESOLVE = "resolve";
export const TOOL_SEARCH = "search";
export const TOOL_MEMBERS = "members";
export const TOOL_ENTITIES = "fetch_entities";
export const TOOL_VALIDATE_PLEX_ID = "validate_plex_id";

export const TOOL_RESOLVE_AGENT = "resolve_agent";
export const TOOL_SEARCH_AGENT = "search_agent";

const RE_POLITE = /^(?:(?:yes, )?please|thank you[^.]*\.|Certainly! *|I apologize, but *) ([a-z])/i;


export const blunt = s => {
  while (RE_POLITE.test(s)) {
    const text = s.replace(RE_POLITE, "$1");
    s = text.charAt(0).toUpperCase() + text.slice(1);
  }
  return s;
};

export const extractSearchParams = (toolInfo, dsConfig) => {
  const {
    instructions,
    useCategories = null,
    snapshotSize = null,
    ...searchParams
  } = toolInfo.input;
  return {simThreshold: DEFAULT_CLIENT_SIM_THRESHOLD, ...searchParams};
}

export const invokeTool = async (info, {dsConfig = 0, templates = {}, auth}) => {
  if (info.name === TOOL_SEARCH_AGENT || info.name === TOOL_SEARCH) {
    // LLM sometimes ignores array requirement here
    if (typeof(info.input.ids) === "string") {
      info.input.ids = info.input.ids.split(",");
    }
    const {
      instructions,
      useCategories, // deprecated
      snapshotSize, // deprecated
      ...params
    } = info.input;
    const searchParams = {
      simThreshold: DEFAULT_CLIENT_SIM_THRESHOLD,
      ids: [],
      ...params,
    };
    return await fetchChatCompletion(searchParams, {
      messages: [{role: "user", content:[{"text": instructions}]}],
      templates,
      useTools: true,
      useCategories,
      snapshotSize,
      role: info.name,
    }, auth);
  }
  if (info.name === TOOL_RESOLVE_AGENT) {
    return await fetchChatCompletion({}, {
      messages: [{role: "user", content:[{"text": info.input.instructions}]}],
      templates,
      useTools: true,
      useMarkdown: false,
      role: info.name,
    }, auth);
  }
  if (info.name === TOOL_RESOLVE) {
    if (typeof(info.input.terms) === "string") {
      info.input.terms = info.input.terms.split(",");
    }
    else if (info.input.terms.length === 1) {
      info.input.terms = info.input.terms[0].split(",");
    }
    return await fetchSuggestions(info.input.terms, auth, {
      category: info.input.category,
      highlight: false,
      dsConfig, instructions: info.input.instructions,
    });
  }
  if (info.name === TOOL_MEMBERS) {
    const members = await fetchMembers(info.input.id, dsConfig, auth);
    // LLM needs an object returned and will choke on a bare array
    return {members}
  }
  if (info.name === TOOL_ENTITIES || info.name === TOOL_VALIDATE_PLEX_ID) {
    const {entities} = await fetchEntities(info.input.ids, dsConfig, auth);
    const missing = new Set(info.input.ids);
    const emap = entities.reduce((result, e) => {
      result[e.id] = e;
      missing.delete(e.id);
      return result;
    }, {});
    Array.from(missing).forEach(id => {
      emap[id] = null;
    });
    if (info.name === TOOL_ENTITIES) {
      return {entities: emap};
    }
    return info.input.ids.reduce((result, id) => {
        result[id] = !!emap[id];
        return result;
      }, {});
  }
  throw new Error(`Invalid tool info '${info.name}'`, info);
};

export default invokeTool;

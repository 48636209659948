import React from "react";
import PropTypes from "prop-types";
import { checkOfflineState } from "./OfflineDetect";

const FilterButton = ({ label, title= "", disabled = false, onClick = () => {}}) => {

  const className = `sr-link${disabled ? " disabled" : ""}`;
  return (
    <button
      title={title}
      onClick={disabled ? () => {} : checkOfflineState(onClick)}
      className={className}
    >
      {label}
    </button>
  );
};

FilterButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FilterButton;

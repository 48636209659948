import { makeAutoObservable, toJS } from "mobx";
import { fetchEChains } from "../queries/echains";
import {patchEntity} from "../lib/utils";
import {toast} from "react-toastify";

class EChainStore {
  _appStatusStore = null;
  _isFetching = false;
  _paths = [];
  _searchSessionStore = null;
  _targetID = "";
  _userStore = null;

  constructor(userData, appStatusStore, searchSessionStore) {
    makeAutoObservable(this);
    this._userStore = userData;
    this._appStatusStore = appStatusStore;
    this._searchSessionStore = searchSessionStore;
  }

  getEChains = async (targetID, datasetsFilter = []) => {
    this.isFetching = true;
    try {
      const {params: searchParams, categories, datasets, templates, preferredNames} = this._searchSessionStore;
      const results = await fetchEChains(searchParams, targetID, datasetsFilter,
          this._userStore.authData,
          s => this._searchSessionStore.searchProgress = s);
      this.targetID = targetID;
      this.paths = (results.paths || []).map(path => (
        path.map(el => {
          if (el.entity) {
            el.entity = patchEntity(el.entity, categories, datasets[el.entity.dataset], templates, preferredNames);
          }
          return el;
        })));
      }
    catch (error) {
      const msg = `Error on receiving echain data: ${
            error.message || error.detail || error.statusText || "(no details)"
          }. targetId = ${targetID}`;
      toast.error(msg, {error: new Error(
          `Error on receiving echain data: ${
            error.message || error.detail || error.statusText || "(no details)"
          }. targetId = ${targetID}`
        )});
      this.paths = [];
      this.targetID = "";
      this.isFetching = false;
    }

    this.isFetching = false;
  };

  get isFetching() {
    return this._isFetching;
  }

  set isFetching(b) {
    this._isFetching = b;
  }

  get targetID() {
    return this._targetID;
  }

  set targetID(t) {
    this._targetID = t;
  }

  get searchParams() {
    return this._searchSessionStore.params;
  }

  get searchIDs() {
    return this.searchParams.searchIDs;
  }

  get directConnectionID() {
    return this.searchParams.directConnectionID;
  }

  get neighborhood() {
    return this.searchParams.neighborhood;
  }

  get simThreshold() {
    return this.searchParams.simThreshold;
  }

  get simFloor() {
    return this.searchParams.simFloor;
  }

  get dsConfig() {
    return this.searchParams.dsConfig;
  }

  get paths() {
    return toJS(this._paths);
  }

  set paths(p) {
    this._paths = p;
  }

}

export default EChainStore;

import React from "react";
import PropTypes from "prop-types";
import { checkOfflineState } from "./OfflineDetect";

const Tab = ({ title, description, onClick, isActive, downloadCount, totalCount, className=null }) => {
  const total = downloadCount !== undefined && totalCount !== undefined ? `\nTotal results: ${totalCount}` : "";
  const progress = downloadCount !== undefined && totalCount !== undefined && downloadCount !== totalCount
    ? `\nLoaded ${downloadCount} of ${totalCount} results` : downloadCount === totalCount ? total : "";
  return totalCount === 0 ? (
    <span
      className={`tab tab-span-disabled disabled${className ? ' ' + className : ''}`}
      title={`${description}${progress}`}
    >
      {title}
    </span>
  ) : (
    <button
      title={`${description}${progress}`}
      onClick={checkOfflineState(onClick)}
      className={`tab${isActive ? " active" : ""}${className ? ' ' + className : ''}`}
    >
      {title}
    </button>
  );
};

Tab.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  description: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

export default Tab;

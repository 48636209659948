import React from "react";
import {inject, observer} from "mobx-react";

const Avatar = ({userStore}) => {
  const {userName} = userStore;
  return userName && (
    <div className={"avatar"}>{userName?.slice(0, 2)}</div>
  );
};

export default inject("userStore")(observer(Avatar));

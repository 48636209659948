import {useNavigate} from "react-router";
import {inject, observer} from "mobx-react";

import {withAuthentication} from "../components/AuthenticateUser";
import {useEffect, useState} from "react";

const Admin = ({userStore}) => {
  const {isAdmin, fetchUsage} = userStore;
  const navigate = useNavigate();
  const [usage, setUsage] = useState({users: {}});
  const [status, setStatus] = useState("");

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = () => {
    fetchUsage()
      .then((info) => {
        setUsage(info);
        setStatus("");
      })
      .catch(error => {
        setStatus(`Error: ${error}`);
      });
  }

  if (!isAdmin) {
    navigate("/", {replace:true});
  }
  document.title = "Plex Usage";
  return (
    <div className="admin-app">
      <h1>Plex Usage</h1>
      <table>
        <thead>
        <tr>
          <td>user</td>
          <td>usage</td>
        </tr>
        </thead>
        <tbody>
        {Object.entries(usage.users || {}).map(([email, count]) => {
          return (<tr key={email}>
            <td>{email}</td>
            <td>{count}</td>
          </tr>);
        })}
        </tbody>
      </table>
      <button onClick={handleRefresh}>Refresh</button>
      <div>Status: {status}</div>
    </div>
  )
};

export default withAuthentication(inject("userStore")(observer(Admin)));

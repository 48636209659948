import localConfig from "../configs/config.json";
import versionInfo from "../configs/versionInfo.json";

export const getConfig = _ => {
  if (localConfig.env === "dev" && !localConfig.patched) {
    // Automatically adjust endpoints when running locally
    const api = localConfig.ExternalRESTAPI;
    Object.entries(api).forEach(([k, v]) => {
      api[k] = v.replace(/^\/api\//, "http://localhost:5000/api/");
    });
    localConfig.patched = true;
  }
  return localConfig;
};

export const getEnv = () => getConfig().env;

export const DEV = getEnv() === "dev";
export const BETA = getConfig().cell === "beta";

export const getVersionInfo = () => versionInfo;

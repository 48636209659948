import {useCallback, useEffect, useRef, useState} from "react";
import {useInterval} from "./useInterval";


export const useTypewriter = (props = {}) => {
  const {delay = 25, initialText = '', initialOffset = 50, emitWords = true} = props || {};
  const [completionCallback, setCompletionCallback] = useState(null);
  const [fullText, setFullText] = useState(initialText);
  const [offset, setOffset] = useState(initialOffset);
  const [displayText, setDisplayText] = useState(fullText.substring(0, offset))
  const isActive = displayText !== fullText;

  useEffect(() => {
    setDisplayText(fullText.slice(0, offset));
  }, [offset, fullText]);

  useEffect(() => {
    if (offset === fullText.length) {
      if (typeof completionCallback === "function") {
        completionCallback(fullText);
      }
      setCompletionCallback(null);
    }
  }, [offset, fullText, completionCallback]);

  useInterval(() => {
    const ch = fullText.charAt(offset);
    if (!emitWords || ch === ' ') {
      setOffset(offset => offset + 1);
    } else {
      const sub = fullText.substring(offset);
      const next = sub.indexOf(" ");
      if (next === -1) {
        setOffset(offset => offset + sub.length);
      } else {
        setOffset(offset => offset +  next + 1);
      }
    }
  }, isActive ? delay : null);

  const startTyping = (text, onTypingComplete = null) => {
    setCompletionCallback(() => onTypingComplete);
    setFullText(text);
    setOffset(initialOffset);
  };
  return [displayText, startTyping];
}

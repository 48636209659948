import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {inject, observer} from "mobx-react";
import {Image} from "react-bootstrap";
import {ServerOfflineError} from "../queries/errors";
import logo from "../img/official_logo.png";
import LoadingAnimation from "./LoadingAnimation";

const AuthenticateUser = ({userStore, appStatusStore, children, allowOffline}) => {

  const isAuthenticated = userStore.isAuthenticated;
  const isAuthorized = userStore.isAuthorized;
  const offline = appStatusStore.serverOffline;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //console.debug(`auth ${isAuthenticated} ${isAuthorized} ${offline}`);
    if (offline === true) {
      if (!allowOffline) {
        navigate("/maintenance", {replace: true, state: {from: location}});
      }
    }
    else if (isAuthenticated === false || isAuthorized === false) {
      navigate("/signin", {replace: true, state: {from: location}});
    }
  }, [isAuthenticated, isAuthorized, offline]);

  useEffect(() => {
    userStore.setNavigateAfterSignin(() => navigate("/signin", {replace: true, state: {from: location}}));
  }, [navigate, location]);

  //const message = "Restoring session...";
  const message = "";
  return isAuthenticated && isAuthorized ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className="authorizing-placeholder">
      <Image src={logo} className="logo"/>
      <div>{message}</div>
      <LoadingAnimation />
    </div>
  );
}

const AuthWrapper = inject("userStore", "appStatusStore")(observer(AuthenticateUser));

export const withAuthentication = (WrappedComponent, allowOffline = false) =>
  (props) => (<AuthWrapper allowOffline={allowOffline}> <WrappedComponent {...props} /> </AuthWrapper>);


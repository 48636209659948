import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import FormLayout from "./auth/FormLayout";
import { inject, observer } from "mobx-react";
import ChatSession from "../components/ChatSession";

const Help = ({embedded = false, userStore, appStatusStore}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const {isAuthorized} = userStore;
  const {serverOffline: offline} = appStatusStore;

  useEffect(() => {
    if (offline) {
      navigate("/maintenance", {replace:true, state: location.state});
    }
    else if (isAuthorized) {
      navigate("/", {replace: true});
    }
  }, [isAuthorized, offline]);

  return (
  <FormLayout id={"helpForm"} className={`help${embedded ? ' embedded' : ''}`}>
    <button
      className={"link"}
      title={"Go back to the signin page"}
      onClick={() => navigate("/signin")}
    >
      Back to login
    </button>
    <ChatSession appStatusStore={appStatusStore} userStore={userStore} />
  </FormLayout>
)
  ;
}

export default inject("appStatusStore", "userStore")(observer(Help));

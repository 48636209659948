import React from "react";
import PropTypes from "prop-types";
import GroupedEdges from "../components/evidence/GroupedEdges.js";
import {inject, observer} from "mobx-react";
import {renderMarkdownWithInterpolation} from "./markdown-factory";
import {TEMPLATE_CONSTANTS} from "./index";
import {EVC_NODE_DEFAULT} from "./evidence/templates";
import LabeledEntity from "../components/LabeledEntity";

const TYPE_NODE = "node";
const TYPE_EDGE = "multiedge";

const EChainMarkdownFactory  = ({
    data: {type, content: edges, entity, similarNeighbor},
    scaleFactor,
    actions,
    highlightedDatasets,
    searchSessionStore: { templates, datasets }
  }) => {
  if (type === TYPE_NODE) {
    if (!entity) {
      console.warn("No entity provided for node");
      return "";
    }
    return renderMarkdownWithInterpolation({
                                             templateType: TEMPLATE_CONSTANTS.EVIDENCE_CHAIN.NODE,
                                             templates,
                                             templateOverrides: {
                                               EvcNode: {
                                                 component: LabeledEntity,
                                                 props: {
                                                   actions,
                                                   scale: scaleFactor,
                                                   dataset: datasets[entity.dataset],
                                                   similarNeighbor},
                                               }
                                             },
                                             entity,
                                             fallback: EVC_NODE_DEFAULT,
                                           });
  }
  if (type === TYPE_EDGE) {
    const highlighted = new Set(highlightedDatasets);
    const hasHighlighted = edges.reduce((result, el) => result || highlighted.has(el.dataset), false);
    return (
      <GroupedEdges
        datasets={datasets}
        scaleFactor={scaleFactor}
        edges={edges?.filter(el => !hasHighlighted || highlighted.has(el.dataset))}
        actions={actions}
        highlightedDatasets={highlightedDatasets}
      />
    );
  }
  return <span>unknown evidence chain fragment type</span>;
}

EChainMarkdownFactory.propTypes = {
  data: PropTypes.object,
  scaleFactor: PropTypes.string,
  actions: PropTypes.any,
  highlightedDatasets: PropTypes.arrayOf(PropTypes.string),
};

export default inject("searchSessionStore")(observer(EChainMarkdownFactory));

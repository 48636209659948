import React, {useCallback, useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import QuerySummary from "../components/query-summary";
import SearchResultsGrid from "../components/results-grid";
import ResultsFilter from "../components/results-grid/ResultsFilter";
import {useResizeDetector} from "react-resize-detector";
import {DELETE_MARKER, naturalSort, setPageTitle} from "../lib/utils";
import SubmitSearchButton from "../components/SubmitSearchButton";
import {fetchConnected} from "../queries/search";
import {toast} from "react-toastify";
import _ from "lodash";
import EvidenceModal from "../components/evidence/EvidenceModal";
import EdgeGraph from "../components/EdgeGraph";
import Select from "react-select";
import LoadingAnimation from "../components/LoadingAnimation";
import Checkbox from "../components/controls/Checkbox";
import EdgeDetails from "../components/EdgeDetails";
import {markMatchingText} from "../markdown/utils";

const MAX_SEARCH_ITEMS = 200;

const RE_SALT = /^\[?([A-IK-Z][a-z]?H?(\d*[-+])?)]?$/;
const desalt = s => (s || "").split(".").filter(s => !RE_SALT.test(s)).join(".");
const findSalts = (q, r) => {
  const qs = new Set(q);
  if (qs.size === 0 || r.length === 0) {
    return [];
  }
  return r.filter(e => {
    const smiles = e.entity.smiles;
    return !qs.has(smiles) && desalt(smiles).split(".").some(s => qs.has(s));
  });
};

const SearchResults = ({searchSessionStore, eChainStore, userStore}) => {

  const [paddingTop, setPaddingTop] = useState(null);
  const [summaryMarginTop, setSummaryMarginTop] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(0);
  const [dsFilteredResults, setDSFilteredResults] = useState(null);
  const [selectedDatasetKeys, setSelectedDatasetKeys] = useState(null);
  const [selectedDatasetsCategory, setSelectedDatasetsCategory] = useState(null);
  const [isDatasetsFilteringBusy, setIsDatasetsFilteringBusy] = useState(false);
  const [includeQueryTerms, setIncludeQueryTerms] = useState(null);
  const [includeSalts, setIncludeSalts] = useState(true);
  const [showEvidence, setShowEvidence] = useState(false);
  const [filteredIDs, setFilteredIDs] = useState(null);
  const [showSimilarity, setShowSimilarity] = useState(true);
  const [showIntermediate, setShowIntermediate] = useState(false);
  const [sortBySimilarity, setSortBySimilarity] = useState(false);
  const [graphLabelFilter, setGraphLabelFilter] = useState("");

  // FIXME may no longer be necessary
  const handleFilterResize = useCallback(() => {
    // Push grid content down below the filter element
    const filter = document.querySelector(".grid-filter");
    if (filter && filter.clientHeight !== paddingTop) {
      setPaddingTop(filter.clientHeight);
    }
  }, [paddingTop]);

  const {ref} = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 1000,
    onResize: handleFilterResize
  });

  const {
    authData,
    awaitingInitialResults,
    categories,
    categoryTotals,
    categoryResults,
    categoryResultsLength,
    categoryResultsTotal,
    currentCategory,
    datasets,
    dsConfig,
    dsConfigDatasets,
    entities,
    exportEvidenceChains,
    getEntityById,
    fetchGraphData,
    filterString,
    graphTargetCategory,
    isFilterMatched,
    isFiltering: isFilteringByString,
    isCategoryLoading,
    params: searchParams,
    queryCategory,
    queryEntities,
    searchIDs: queryIDs,
  } = searchSessionStore;

  const {simType} = searchParams;

  const clearSelectedDatasets = () => {
    console.debug(`Clear datasets filter`);
    setSelectedDatasetKeys(null);
    setSelectedDatasetsCategory(null);
    setIsDatasetsFilteringBusy(false);
    setDSFilteredResults(null);
  };

  const handleEvidenceClose = () => setShowEvidence(false);

  const handleDatasetsFilterChange = datasets => {
    const keys = datasets?.map(x => x.id).sort();
    if (!keys?.length) {
      clearSelectedDatasets();
    } else if (selectedDatasetsCategory !== currentCategory
               || !_.isEqual(keys, selectedDatasetKeys)) {
      console.debug(`Datasets filter set ${JSON.stringify(keys)}`);
      setSelectedDatasetKeys(keys);
      setSelectedDatasetsCategory(currentCategory);
    }
    else {
      console.debug("No change to datasets filter")
    }
  };

  useEffect(() => {
    if (selectedDatasetKeys == null) {
      setFilteredIDs(null);
      return () => {};
    }
    let ignore = false;
    setIsDatasetsFilteringBusy(true);
    fetchConnected(searchParams, selectedDatasetKeys, currentCategory, authData)
      .then(({ids}) => {
        if (!ignore) {
          setFilteredIDs(ids);
        }
      })
      .finally(() => {
        if (!ignore) {
          setIsDatasetsFilteringBusy(false);
        }
      });
    return () => ignore = true;
  }, [selectedDatasetKeys, selectedDatasetsCategory, categoryResults]);

  useEffect(() => {
    if (filteredIDs != null) {
      const includedIDs = new Set(filteredIDs);
      const filtered = categoryResults.filter(x => includedIDs.has(x['id']));
      setDSFilteredResults(filtered);
    }
    else {
      setDSFilteredResults(null);
    }
  }, [filteredIDs, categoryResults]);

  const handleFilterInputChange = input => {
    searchSessionStore.filterString = input;
  };

  const handleIncludeQueryTermsChange = show => {
    setIncludeQueryTerms(show);
  };

  const handleIncludeSaltsChange = show => {
    setIncludeSalts(show);
  };

  const handleGridScroll = useCallback(e => {
    const grid = e.target;
    const {scrollTop: scrollOffset} = grid;
    const scrollDirection = previousOffset < scrollOffset ? "forward" : "backward";
    const el = document.querySelector(".query-summary-contents");
    if (el) {
      let delta = scrollOffset - summaryMarginTop;
      const bounds = el.getBoundingClientRect();
      const BOTTOM_MARGIN = 10;
      const bottom = bounds.bottom + BOTTOM_MARGIN;
      if (scrollDirection === "forward") {
        if (bottom > window.innerHeight) {
          delta = Math.min(bottom - window.innerHeight, delta);
        }
        else {
          delta = 0;
        }
      }
      else if (scrollDirection === "backward") {
        delta = scrollOffset - previousOffset;
        if (summaryMarginTop > 0) {
          delta = Math.max(-summaryMarginTop, delta);
        }
        else {
          delta = 0;
        }
      }
      if (delta !== 0) {
        setSummaryMarginTop(summaryMarginTop + delta);
      }
      if (scrollOffset !== previousOffset) {
        setPreviousOffset(scrollOffset);
      }
    }
  }, [summaryMarginTop, previousOffset]);

  const markText = useCallback((text, asString = false) => {
    if (isFilteringByString) {
      //console.log(`Mark ${filterString} within ${text}`);
      return markMatchingText(text, filterString, {asString});
    }
    return text;
  }, [filterString, isFilteringByString]);
  markText.toString = () => `[mark occurrences of '${filterString}']`;

  // FIXME change this to navigate to the graph instead
  const handleShowEvidence = (targetID, targetCategory) => {
    console.debug(`handleShowEvidence ${JSON.stringify(Array.from(selectedDatasetKeys || []))}`);
    eChainStore.getEChains(targetID, selectedDatasetKeys);
    setShowEvidence(true);
  };

  useEffect(() => {
    if (includeQueryTerms !== null && (!queryEntities || (isCategoryLoading && categoryResultsLength === 0))) {
      setIncludeQueryTerms(null);
    }
    if (selectedDatasetsCategory != null && selectedDatasetsCategory !== currentCategory) {
      clearSelectedDatasets()
    }
  }, [includeQueryTerms, queryEntities, isCategoryLoading, categoryResultsLength, selectedDatasetsCategory, currentCategory]);

  const edgeDatasets = Object.entries(dsConfigDatasets).reduce((result, [k, v]) => {
    if (v.edges || k === "bingo") {
      result[k] = v;
    }
    return result;
  }, {});

  const isDSFilterActive = selectedDatasetsCategory === currentCategory && selectedDatasetKeys != null;
  const isFiltering = isFilteringByString || isDSFilterActive;
  const currentCategoryLabel = (categories && categories[currentCategory] && categories[currentCategory].name.toLowerCase()) || "";
  const qe = queryEntities ? [...queryEntities] : [];
  const queryEntitiesOnPage = qe.filter(x => x.category === currentCategory);
  const noResults = categoryResultsTotal === 0;
  const filterClass = 'grid-filter'
  const filterClassName = `${filterClass}${noResults && filterString === '' ? ' hidden' : ''}`;
  const showWaiting = awaitingInitialResults || isDatasetsFilteringBusy;

  const showIncludeQueryTermsToggler = queryEntitiesOnPage.length > 2 && categoryResultsLength > queryEntitiesOnPage.length;
  const forceShowQueryTermMatches = (queryEntitiesOnPage.length < 3 || categoryResultsLength <= queryEntitiesOnPage.length)
    && queryEntitiesOnPage.length > 0;

  useEffect(() => {
    setIncludeQueryTerms(forceShowQueryTermMatches
                           || (includeQueryTerms == null ? !showIncludeQueryTermsToggler : includeQueryTerms));
  }, [forceShowQueryTermMatches, includeQueryTerms, showIncludeQueryTermsToggler])

  //const salts = new Set(currentCategory !== "compound" ? []
  //    : findSalts(queryEntitiesOnPage.map(e => e.smiles).filter(x => x), categoryResults)
  //        .map(e => e.entity.smiles));
  //const showIncludeSaltsToggler = salts.size > 2 && categoryResultsLength > salts.size;
  const salts = new Set();
  const showIncludeSaltsToggler = false;

  const summaryStyle = summaryMarginTop !== null ? {
    marginTop: `${-summaryMarginTop}px`
  } : {};

  const searchTitle = `Submit top ${MAX_SEARCH_ITEMS} results as a new search`;
  const querySet = new Set(qe.map(x => x.id));
  const categoryResultsSet = new Set(categoryResults.map(x => x.id));
  const onlySearchTermsVisible = querySet.size >= categoryResultsSet.size && [...categoryResultsSet].every((x) => querySet.has(x));
  const displayedResults = isDSFilterActive ? (dsFilteredResults || []) : categoryResults;
  const disableSearchButton = isCategoryLoading || onlySearchTermsVisible || displayedResults.length <= queryEntities.length;
  //console.debug(`Displaying ${displayedResults?.length} (ds filter=${isDSFilterActive}) available=${categoryResults?.length}`);
  const [graphInfo, setGraphInfo] = useState(null);
  const [fetchingGraphData, setFetchingGraphData] = useState(false);
  const [edgeSource, setEdgeSource] = useState(null);
  const [edgeTarget, setEdgeTarget] = useState(null);
  const [edgeAttributes, setEdgeAttributes] = useState({});

  const isGraph = currentCategory === "graph";

  useEffect(() => {
    setGraphInfo(null);
    setEdgeSource(null);
    setEdgeTarget(null);
    setEdgeAttributes({});
  }, [graphTargetCategory]);

  useEffect(() => {
    if (isGraph && !awaitingInitialResults
        && graphTargetCategory != null
        && (graphInfo == null || graphInfo.targetCategory !== graphTargetCategory)) {
      setFetchingGraphData(true);
      setGraphInfo(null);
      fetchGraphData(graphTargetCategory)
        .then(info => {
          setGraphInfo(info);
        })
        .catch(error => {
          if (error instanceof Response) {
            error = `[${error.status}] ${error.statusText}`;
          }
          console.error("Error fetching graph", error);
          toast.error(`Error fetching graph: ${error}`);
        })
        .finally(() => setFetchingGraphData(false));
    }
  }, [isGraph, awaitingInitialResults, graphTargetCategory]);

  setPageTitle(qe);

  const onNodeHover = (entity) => {

  };

  const onNodeSelect = (entity) => {

  };

  const onEdgeSelect = (fromID, toID, attrs) => {
    if (fromID && toID) {
      setEdgeSource(getEntityById(fromID));
      setEdgeTarget(getEntityById(toID));
      setEdgeAttributes(attrs);
    }
    else {
      setEdgeSource(null);
      setEdgeTarget(null);
      setEdgeAttributes(null);
    }
  };

  const categoryOptions = Object.keys(categories)
    .filter(key => categoryTotals.size === 0
                   || (key === queryCategory
                       ? (categoryTotals.get(key) || 0) > queryIDs.length
                       : (categoryTotals.get(key) || 0) > 0))
    .map(key => ({
      label: categories[key].name,
      value: key,
    }))
    .sort((a, b) =>
            naturalSort(a.label, b.label));
  // categoryOptions.insert(0, {label: "All", value: "all"})
  const {
    graph: {
      longest: columns,
      adjacency: adjacencyList = [],
      nodes: nodesData = []
    } = {},
    targetCategory
  } = graphInfo || {};

  const getNodeLabel = (id) => {
    return entities[id]?.label;
  }

  return (
    <div className="page-content">
      {showWaiting ? (
        <div className="search-results search-results-loading">
          <div className={"grid-loading"}><LoadingAnimation/></div>
          <QuerySummary queryEntities={qe} style={summaryStyle}/>
        </div>
        ) : (
      <div className={`search-results`}>
        {isGraph
         ? (
            <>
              <div className={"graph-results"}>
                <div className={"graph-controls"}>
                  <input
                    key={"node-filter"}
                    placeholder="find..."
                    onChange={(e) => setGraphLabelFilter(e.target.value)}
                    onKeyDown={(e) => e.key === "Escape" && setGraphLabelFilter("")}
                    type="text"
                    value={graphLabelFilter}
                    className={isFilterMatched ? "" : "out-of-bounds"}
                    title={isFilterMatched ? "" : "Nothing matched"}
                  />
                  <span
                    key={"node-filter-reset"}
                    className={`input-reset inside${graphLabelFilter !== "" ? "" : " disabled"}`}
                    title="Clear input"
                    onClick={() => setGraphLabelFilter("")}
                    style={graphLabelFilter !== "" ? {visibility: "visible"} : {}}>
                    {DELETE_MARKER}
                  </span>
                  <label><span>Show links from {categories[queryCategory].name} to</span>
                    <Select
                      placeholder={"Choose a category"}
                      className={"category-select"}
                      classNamePrefix={"category-select"}
                      defaultValue={graphTargetCategory && graphTargetCategory !== "all"
                                    ? {label: categories[graphTargetCategory].name, value: graphTargetCategory}
                                    : {label: "All", value: "all"}}
                      onChange={option => searchSessionStore.graphTargetCategory = option.value}
                      options={categoryOptions}
                      isSearchable={false}
                    />
                  </label>
                  {queryCategory === "compound" && (
                    <>
                      <Checkbox
                        checked={sortBySimilarity}
                        label={"Sort by Similarity"}
                        disabled={simType === ""}
                        onChange={(checked) => setSortBySimilarity(checked)}
                      />
                      <Checkbox
                        checked={!showSimilarity}
                        label={"Hide Similarity"}
                        disabled={simType === ""}
                        onChange={(checked) => setShowSimilarity(!checked)}
                      />
                    </>
                  )}
                  <Checkbox
                    checked={!showIntermediate}
                    label={"Hide Intermediate"}
                    onChange={(checked) => setShowIntermediate(!checked)}
                  />
                </div>
                {graphTargetCategory == null
                 ? null
                 : fetchingGraphData
                   ? (<LoadingAnimation network={true} />)
                   : (<div className="graph-view">
                        <EdgeGraph
                          columnCount={columns}
                          nodesData={nodesData}
                          adjacencyList={adjacencyList}
                          queryCategory={queryCategory}
                          targetCategory={targetCategory}
                          queryIDs={queryIDs}
                          datasets={datasets}
                          showSimilarity={showSimilarity}
                          showIntermediate={showIntermediate}
                          sortBySimilarity={sortBySimilarity}
                          onEdgeSelect={onEdgeSelect}
                          labelFilter={graphLabelFilter}
                          getNodeLabel={getNodeLabel}
                        />
                      </div>)}
              </div>
              <EdgeDetails
                edgeSource={edgeSource}
                edgeTarget={edgeTarget}
                edgeAttributes={edgeAttributes}
                datasets={datasets} />
            </>
         ) : (
           <>
             <ResultsFilter
               /*ref={ref}*/
               className={filterClassName}
               currentCategory={currentCategory}
               currentCategoryLabel={currentCategoryLabel}
               filterString={filterString}
               onFilterStringChange={handleFilterInputChange}
               onDatasetsFilterChange={onlySearchTermsVisible ? null : handleDatasetsFilterChange}

               onIncludeQueryTermsChange={handleIncludeQueryTermsChange}
               includeQueryTerms={includeQueryTerms}
               showIncludeQueryTermsToggler={showIncludeQueryTermsToggler}

               onIncludeSaltsChange={handleIncludeSaltsChange}
               includeSalts={includeSalts}
               showIncludeSaltsToggler={showIncludeSaltsToggler}

               displayedCount={displayedResults.length}
               totalCount={categoryResultsTotal}
               showWaiting={showWaiting}
               showLoading={isCategoryLoading}
               isFiltering={isFiltering}
               isFilterMatched={isFilterMatched}
               enableDSFilter={queryCategory !== currentCategory}
               isDatasetsFilteringBusy={isDatasetsFilteringBusy}
               filteringDatasetKeys={isDSFilterActive ? selectedDatasetKeys : Object.keys(edgeDatasets)}
               datasets={edgeDatasets}
               searchButton={(
                 <SubmitSearchButton
                   ids={displayedResults.map(e => e['id']).slice(0, MAX_SEARCH_ITEMS)}
                   dsConfig={dsConfig}
                   category={currentCategory}
                   title={searchTitle}
                   label=""
                   disabled={disableSearchButton}
                 />
               )}
               getCategoryResultsClipboardText={() => searchSessionStore.categoryClipboardText }
               isAdmin={userStore.isAdmin}
             />
             <QuerySummary queryEntities={qe} style={summaryStyle}/>
             <SearchResultsGrid
               showLoading={isCategoryLoading}
               paddingTop={paddingTop}
               onShowEvidence={handleShowEvidence}
               onScroll={handleGridScroll}
               markText={isFilteringByString ? markText : null}
               includeQueryTerms={includeQueryTerms}
               isFiltering={isFilteringByString || isDSFilterActive}
               items={displayedResults}
             />
           </>)}
      </div>
      )}
      <EvidenceModal
        showModal={showEvidence}
        onClose={handleEvidenceClose}
        highlightedDatasets={selectedDatasetKeys}
        isFetching={eChainStore.isFetching}
        evidenceChains={eChainStore.paths}
        targetID={eChainStore.targetID}
        exportFn={exportEvidenceChains}
        datasets={datasets}
      />
    </div>
  );
};

//export default withPropsChecker(inject("searchSessionStore", "eChainStore", "userStore")(observer(SearchResult)));
export default inject("searchSessionStore", "eChainStore", "userStore")(observer(SearchResults));

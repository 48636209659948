import {useEffect, useMemo, useRef, useState} from "react";
import {debounce, throttle} from "lodash";

export const useDebounce = (callback, delay = 400, options = {}) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = callback;
  }, [callback])
  return useMemo(() => {
    const fn = () => ref.current?.();
    return debounce(fn, delay, {leading: true, ...options});
  }, [delay]);
};

export const useThrottle = (callback, minInterval = 400) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = callback;
  }, [callback])
  return useMemo(() => {
    return throttle(() => ref.current?.(), minInterval);
  }, [minInterval]);
};

export const useDebouncedValue = (value, wait = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(id);
  }, [value]);
  return debouncedValue;
};

import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import Select, {components} from "react-select";
import SelectedOptionsDetails from "./modals/SelectedOptionsEditor";
import _, {escapeRegExp, isEqual} from "lodash";
import {TAXID_NONE} from "../stores/AppStatusStore";
import LoadingAnimation from "./LoadingAnimation";
import {renderMarkdownWithInterpolation} from "../markdown/markdown-factory";
import {FALLBACKS} from "../markdown/autosuggest/template-fallbacks";
import {TEMPLATE_CONSTANTS} from "../markdown";
import {toast} from "react-toastify";

import {mark, markMatchingText} from "../markdown/utils";


// Set this true to prevent the popup options menu from closing automatically
const OPTIONS_DEBUG_POPUP = false;
const OPTIONS_DEBUG_LOADING = false;
const OPTIONS_PAGE_SIZE = 5;
const MIN_COMPLETION_LENGTH = 3;
// word joiner character (aka @nbsp;); identifies text set externally on this component
const AUTOSELECT_TAG = "\u2060";

const LoadingMessage = ({innerRef, innerProps}) => (<LoadingAnimation ref={innerRef} {...innerProps}/>);

const SearchBoxInput = (props) => {
  return (
    <components.Input
      {...props}
      disabled={props.selectProps.disabled}
      onPaste={props.selectProps.onPaste}
    />
  );
};

// FIXME needs tooltip on "show more..."
const Option = ({innerRef, innerProps, isFocused, data = {}, selectProps: {templates = {}, markText = null}}) => {
  const className = `as-option${innerProps.className && innerProps.className !== "" 
    ? ' ' + innerProps.className : ''}${isFocused ? " focused" : ""}`;
  const {onClick = innerProps.onClick} = data;
  const type = data.templateType || TEMPLATE_CONSTANTS.AUTO_SUGGEST.GROUP_HEADER;
  const optionElement = renderMarkdownWithInterpolation({
                                                          templateType: type,
                                                          templates, templateOverrides: {},
                                                          entity: {...(data || {}), ...(data?.entity || {})},
                                                          markText,
                                                          fallback: FALLBACKS[type]});
  return (
    <div ref={innerRef} {...innerProps} className={className} onClick={onClick} >{optionElement}</div>
  );
};

const RESOLVING_TAG = "resolving-terms-token";
const RESOLVING_VALUE = {
  id: RESOLVING_TAG,
  label: RESOLVING_TAG,
  className: RESOLVING_TAG
};

const MultiValue = (props) => {

  const {
    isFocused,
    selectProps: {value: values},
    data: {
      id,
      className = "",
      onClick = null,
    }
  } = props;
  const isValueToken = values.length && id === values[0].id;
  const isResolvingToken = id === RESOLVING_TAG;
  const classNames = [];
  if (className !== "") {
    classNames.push(className);
  }
  if (isFocused) {
    classNames.push("focused");
  }
  const overrides = {};
  if (isResolvingToken) {
    overrides.children = "";
  }
  else if (isValueToken) {
    if (values.length > 1) {
      overrides.children = `${values.length} items`;
      props.data.title = "Multiple items, click to see details";
    }
    else {
      props.data.title = `${values[0].entity.label}, click to see details`;
    }
  }
  if (onClick) {
    overrides.onClick = onClick;
  }
  // Only display if it's the last instance or the resolving terms token
  return (isValueToken || isResolvingToken) && (
    <components.MultiValue
      className={classNames.join(" ")}
      {...props}
      {...overrides} />
  )
}

const ResolvingTermsToken = ({text}) => (
  <div className="resolving-placeholder">
    <div className="spinner"/>
    <div className="placeholder-text">{text}</div>
  </div>
);

const MultiValueLabel = (props) => {
  const overrides = {};
  const innerProps = {
    ...(props.innerProps || {}),
    // Prevent the options menu from opening when value label is clicked
    onMouseDown: (e) => {
      e.stopPropagation();
      e.preventDefault();
    }
  };
  const {selectProps: {resolvingTermsMessage = ""}, data: {onClick = null, label = null, title = ""}} = props;
  if (onClick) {
    innerProps.onClick = onClick;
  }
  innerProps.title = title;
  if (label === RESOLVING_TAG) {
    overrides.children = [<ResolvingTermsToken key="resolving-terms" text={resolvingTermsMessage} />];
  }
  return <components.MultiValueLabel {...props} {...overrides} innerProps={innerProps} />;
}

const SearchTermEditor = (
  {
    fetchSuggestions,
    onValueChange,
    onInputChange,
    onNewOptionsObtained,
    onResolvingChange,
    onRunNewSearch,
    defaultSearchTerms,
    searchTerms,
    textToResolve = "",
    termsBeingResolved : initialTermsBeingResolved,
    showRemainingOptions = true,
    templates,
    searchButton = null,
    searchProps = {},
  }) => {
  const entityToOption = (entity) => {
    return {...entity, entity};
  };
  const selectRef = useRef();
  const [textFieldValue, setTextFieldValue] = useState(textToResolve || "");
  const [isFetchingOptions, setIsFetchingOptions] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(searchTerms.map(x => entityToOption(x)));
  const [termsBeingResolved, setTermsBeingResolved] = useState(initialTermsBeingResolved);
  const [options, setOptions] = useState([]);
  const [showSelectedOptions, setShowSelectedOptions] = useState(false);
  const [suggestions, setSuggestions] = useState(false);
  const [visibleOptionCount, setVisibleOptionCount] = useState({});

  const initFromURL = initialTermsBeingResolved?.length > 0;

  useEffect(() => {
    setTermsBeingResolved(initialTermsBeingResolved);
    onResolvingChange(initialTermsBeingResolved?.length > 0);
  }, [initialTermsBeingResolved]);

  useEffect(() => {
    if (searchTerms?.length) {
      const options = searchTerms.map(x => entityToOption(x));
      if (!_.isEqual(new Set(searchTerms.map(x => x.id)), new Set(selectedOptions.map(x => x.id)))) {
        setSelectedOptions(options);
      }
      setTermsBeingResolved(false);
    }
    else if (selectedOptions.length) {
      setSelectedOptions([]);
    }
  }, [searchTerms]);

  useEffect(() => {
    if (textToResolve !== "") {
      handleTextBlockInput(`${textToResolve}${AUTOSELECT_TAG}`);
    }
  }, [textToResolve]);

  useEffect(() => {
    if (selectedOptions) {
      onValueChange(selectedOptions.map(x => x.entity))
    }
  }, [selectedOptions]);

  useEffect(() => {
    onInputChange(textFieldValue);
  }, [textFieldValue]);

  useEffect(() => {
    onNewOptionsObtained(suggestions);
  }, [suggestions]);

  // NOTE: lodash.debounce doesn't work for async functions
  const debouncedFetchSuggestions = useCallback(_.debounce((input, restrictToCategory, canceled = () => false, autoselect = false) => {
    const selectedIDs = new Set(selectedOptions.map(x => x.id));
    fetchSuggestions(input, restrictToCategory)
      .then((suggestions) => {
        if (canceled()) {
          console.debug(`Obsolete suggestions for "${input}" ignored`, suggestions);
        }
        else {
          console.debug(`Suggestions returned for "${input}"`, suggestions);
          if (suggestions.length === 0) {
            if (input?.startsWith("InChI=")) {
              toast.warning("The given InChI is not valid");
            }
          }
          setIsFetchingOptions(false);
          const newSuggestions = suggestions.filter(x => !selectedIDs.has(x.id))
          if (autoselect && newSuggestions.length === 1) {
            handleResolvedTerms(newSuggestions, input, [...selectedOptions]);
          }
          else {
            if (!isEqual(new Set(suggestions.map(x => x.id), new Set(newSuggestions.map(x => x.id))))) {
              setSuggestions(newSuggestions);
            }
          }
        }
      })
      .catch((error) => {
        if (!canceled()) {
          setIsFetchingOptions(false);
          setSuggestions(false);
          setFetchError(`Failed to load suggestions for "${input}"`);
        }
      });
  }, 400), [selectedOptions]);

  // Obtain options when the input changes
  useEffect(() => {
    const autoselect = textFieldValue.endsWith(AUTOSELECT_TAG);
    const inputString = textFieldValue.replace(AUTOSELECT_TAG, "").trim();
    if (inputString.length < MIN_COMPLETION_LENGTH) {
      clearOptions();
      return () => {};
    }
    const restrictToCategory = selectedOptions.length > 0
      ? selectedOptions[0].entity.baseCategory
      : null;
    clearOptions();
    setIsFetchingOptions(inputString);
    let canceled = false;
    debouncedFetchSuggestions(inputString, restrictToCategory, () => canceled, autoselect);
    return () => {canceled = true};
  }, [textFieldValue]);

  const handleRemoveSelectedOption = (id) => {
    const updatedOptions = selectedOptions.filter(x => x.id !== id);
    setSelectedOptions(updatedOptions);
    if (updatedOptions.length === 0) {
      setShowSelectedOptions(false);
    }
  };

  const clearOptions = () => {
    setIsFetchingOptions(false);
    setSuggestions(false);
    setVisibleOptionCount({});
  };

  const reset = () => {
    setTextFieldValue("");
    setSelectedOptions([]);
    clearOptions();
  };

  const splitTerms = (input = "") => {
    // Avoid splitting extended smiles containing |$.*$|
    return input.replaceAll(/([\r\n\t]|(?<!\|\$[^|]+);(?![^|]+\$\|))/gi, "\n")
      .split("\n");
  }

  const parseTerms = (input = "") => {
    const elements = [];
    if (input && input.length > 0) {
      const lines = splitTerms(input);
      if (lines.length > 1) {
        elements.push(...lines.map(x => x.trim()));
      }
      else if (lines.length === 1) {
        elements.push(lines[0]);
      }
    }
    return (
      elements
        // remove all-whitespace strings
        .filter(x => x.length > 0)
        // return only unique values
        .filter((v, i, a) => a.indexOf(v) === i)
    );
  };

  const showSelectedOptionsModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSelectedOptions(true);
  };

  const resolvingMessage = (terms) => {
    if (terms?.length > 1) {
      return `Resolving ${terms.length} terms...`;
    }
    if (terms?.length === 1) {
      return `Resolving ${terms[0].replace(/^(smiles|query|inchi|inchikey):/, '')}...`;
    }
    return "Resolving...";
  };

  const restoreFocus = () => {
    // Direct call may not work, so call w/ a little delay as well
    selectRef.current?.focus();
    setTimeout(() => {
      selectRef.current?.focus();
    }, 200);
  }

  // Resolve multiple terms, on init or paste
  const resolveTerms = (terms = [], currentOptions = []) => {
    setFetchError(null);
    setTextFieldValue("");
    setTermsBeingResolved(terms);
    const newSearchTerms = [...terms];
    const updatedValues = [].concat(currentOptions);
    const restrictToCategory = currentOptions.length > 0 ? currentOptions[0].entity.baseCategory : null;
    fetchSuggestions(newSearchTerms, restrictToCategory,
                     newSearchTerms.length === 1 ? TAXID_NONE : null)
      .then(s => handleResolvedTerms(s, newSearchTerms, updatedValues))
      /*.catch(error => {
        console.error(`Failed to resolve terms (${error}`);
        setFetchError(`Failed to resolve terms (${error})`);
      })*/
      .finally(() => {
        setTermsBeingResolved(false);
        restoreFocus();
      });
  }

  // Handle results of a paste of multiple values
  const handleResolvedTerms = (suggestions, newSearchTerms, updatedValues) => {
    const currentIDs = new Set(updatedValues.map(x => x.id));
    const resolved = new Set(suggestions.filter(x => x?.id).map(x => x["match-term"]));
    const unresolved = new Set(newSearchTerms.filter(x => !resolved.has(x)));
    const duplicates = new Set(Array.from(resolved).filter(x => x?.id && currentIDs.has(x?.id))
          .map(x => `${x['match-term']} (${x?.id})`));
    const coincident = {};
    const termResults = {resolved, unresolved, duplicates, coincident};
    const targets = suggestions.filter(x => x?.id && x.category === "target");
    const geneSymbols = targets.filter(x => x && x["gene_symbol"] === x["match-term"]);
    const ambiguousTerms = geneSymbols.length > 0
        ? targets.filter(x => x && x["match-term"] !== x["id"] && x["match-term"] !== x["gene_symbol"]) : [];
    // Keep only unique entries, excluding existing token IDs
    const newIDs = _(suggestions.filter(x => x?.id && !currentIDs.has(x.id)))
      .uniqBy("id")
      .value();

    // Catch multiple search terms resolved into a single ID
    if (newIDs.length < newSearchTerms.length) {
      const mappedTerms = updatedValues.concat(suggestions.filter(x => x?.id)).reduce((result, el) => {
        if (result[el.id]) {
          result[el.id].push(el)
        }
        else {
          result[el.id] = [el];
        }
        return result;
      }, {});
      Object.entries(mappedTerms).forEach(([id, terms]) => {
        if (terms.length > 1) {
          terms.filter(term => !currentIDs.has(term.id))
            .forEach(term => termResults.coincident[term["match-term"]] = term.id);
        }
      });
    }

    updatedValues.push(...newIDs);

    const messages = [];
    if (ambiguousTerms.length) {
      ambiguousTerms.forEach(x => x["ambiguous"] = true);
      messages.push({
        title: "Ambiguous match",
        message: "One or more terms ambiguously matched",
        list: ambiguousTerms.map(x => `${x["match-term"]} => ${x["title"]} [${x["gene_symbol"]}]`),
      });
    }

    if (Object.entries(termResults.coincident).length > 0) {
      const items = Object.entries(termResults.coincident)
          .map(([k, v]) => `${k} => ${v}`);
      const coincident = (termResults.coincident.size <= 4
          ? items : [...items.slice(0, 3), "..."]).join(", ");
      const msg = `${Object.entries(termResults.coincident).length} term(s) have non-unique resolution(s): ${coincident}`;
      messages.push({title: "Coincident", message: msg, list: items});
    }
    if (termResults.unresolved.size > 0) {
      const unmatched = termResults.unresolved.size <= 4
                        ? Array.from(termResults.unresolved).join(", ")
                        : [...Array.from(termResults.unresolved).slice(0, 3), "..."].join(", ");
      const msg = termResults.resolved.size === 0
                  && termResults.duplicates.size === 0
                  ? "No terms matched" : `${termResults.unresolved.size} term(s) were not matched: ${unmatched}`;
      messages.push({title: "No match", message: msg, list: Array.from(termResults.unresolved)});
    }
    if (termResults.duplicates.size > 0) {
      const duplicateMsg = termResults.duplicates.size <= 4
                        ? Array.from(termResults.duplicates).join(", ")
                        : [...Array.from(termResults.duplicates).slice(0, 3), "..."].join(", ");
      const msg = `A match already exists for ${termResults.duplicates.size} term(s): ${duplicateMsg}`;
      messages.push({title: "Duplicate term", message: msg, list: Array.from(termResults.duplicates)});
    }

    messages.forEach(w => w?.message && toast.warning(w.message, {data: w}));

    setSuggestions(false);
    setSelectedOptions(updatedValues);
    setTextFieldValue("");
    setTermsBeingResolved(false);
  };

  const showMoreOptions = (category, numberToShow = null) => {
    const updatedCounts = {
      ...visibleOptionCount,
      [category]: numberToShow || ((visibleOptionCount[category] || OPTIONS_PAGE_SIZE) + OPTIONS_PAGE_SIZE),
    };
    setVisibleOptionCount(updatedCounts);
  };

  useEffect(() => {
    setOptions(formatOptions(suggestions, visibleOptionCount));
  }, [suggestions, selectedOptions, visibleOptionCount, showRemainingOptions]);

  const formatOptions = (suggestions, visibleOptionCount = {}) => {
    const restrictToCategory = selectedOptions?.length > 0 ? selectedOptions[0].entity.category : false;
    const selected = new Set(selectedOptions.map(option => option.id));
    suggestions = (suggestions
      && suggestions?.filter(el => (
        !selected.has(el.id)
        && (!restrictToCategory || el.entity.category === restrictToCategory))
      ));
    if (!suggestions?.length) {
      return [];
    }

    const topCategory = suggestions[0].entity.category;
    // Make sure the top item's category gets grouped first
    const groupedSuggestions = _.groupBy(suggestions.slice(1).sort((a, b) => {
      if (a.entity.category === topCategory) {
        if (b.entity.category === topCategory) {
          return b['match-score'] - a['match-score'];
        }
        return -1;
      }
      if (b.entity.category === topCategory) {
        return 1;
      }
      return b['match-score'] - a['match-score'];
    }), obj => obj.entity.category);
    const top = suggestions[0];
    top.templateType = TEMPLATE_CONSTANTS.AUTO_SUGGEST.TOP;
    const options = [top];

    // Allow selection all of a set of "similar" items (for the top AS hit only)
    // - all items based on the same cell line
    // - all genes with a common symbol (same gene across species)
    const similar = [top];
    let label = "Select similar";
    if (topCategory === 'cell-line') {
      const RE_CELL_LINE = /^.*?:(ACH-[0-9]+).*$/;
      const ach = top.id.replace(RE_CELL_LINE, "$1");
      if (groupedSuggestions[topCategory]) {
        similar.push(...groupedSuggestions[topCategory]
          .filter(option => option.id && option.id.replace(RE_CELL_LINE, "$$1" === ach)));
        label = `Select references to this cell line (${similar.length})`;
      }
    }
    else if (topCategory === 'target' && top.entity.gene_symbol) {
      const symbol = top.entity.gene_symbol.toLowerCase();
      if (groupedSuggestions[topCategory]) {
        similar.push(...groupedSuggestions[topCategory]
          .filter(option => (option.entity.gene_symbol || "").toLowerCase() === symbol));
        label = `Select across species (${similar.length})`;
      }
    }
    if (similar.length > 1) {
      options.push({
                     label,
                     value: similar,
                     templateType: TEMPLATE_CONSTANTS.AUTO_SUGGEST.SELECT_GROUP,
                     onClick: (e) => setSelectedOptions([...selectedOptions, ...similar]),
                   });
    }

    // Format groups as per Select component requirements
    Object.entries(groupedSuggestions).forEach(
      ([category, list]) => {
        const totalCount = list.length;
        const visibleCount = visibleOptionCount[category] || OPTIONS_PAGE_SIZE;
        const categoryLabel = list[0].entity.categoryLabel;
        list = list.slice(0, visibleCount);
        list.forEach(option => option.templateType = TEMPLATE_CONSTANTS.AUTO_SUGGEST.ITEM);
        options.push({label: categoryLabel, options: list});
        // add pager item if there are more options to display
        if (visibleCount < totalCount) {
          const label = showRemainingOptions
                        ? `show remaining ${categoryLabel.toLowerCase()}`
                        : `show more ${categoryLabel.toLowerCase()} (${visibleCount}/${totalCount})`;
          list.push({
                      label,
                      value: "show-more",
                      title: `${totalCount} ${categoryLabel.toLowerCase()} hits`,
                      templateType: TEMPLATE_CONSTANTS.AUTO_SUGGEST.GROUP_PAGER,
                      onClick: (e) => showMoreOptions(category, showRemainingOptions ? totalCount : null)
          });
        }
      });

    return options;
  };

  const handleValueChange = (newValue, actionMeta) => {
    const {action} = actionMeta;
    if (_.isEqual(selectedOptions.map(x => x.id), newValue.map(x => x.id))) {
      return;
    }
    if (action === "pop-value" || action === "remove-value") {
      newValue = [];
    }
    setTextFieldValue("");
    setSuggestions(false);
    setSelectedOptions(newValue);
  };

  const handleHideSelectedOptions = () => {
    setShowSelectedOptions(false);
  };

  const handleValueClick = (clickedToken) => {
    setShowSelectedOptions(true);
    return clickedToken;
  };

  const handlePaste = (e) => {
    // Avoid generating Select.onInputChange event for this action
    e.preventDefault();
    e.stopPropagation();
    // Snapshot pasted text from clipboard to avoid losing newlines
    const pastedText = e.clipboardData.getData("text/plain").trim();
    console.debug(`Pasted text ${pastedText}`);
    handleTextBlockInput(pastedText, e.target);
    restoreFocus();
  };

  // Handle pasted text or text set from mol editor
  const handleTextBlockInput = (insertedText, input = null) => {
    const terms = parseTerms(insertedText);
    // On paste only create tokens if several terms were pasted, otherwise handle like text was typed
    if (terms.length > 1) {
      // NOTE: leaves any extant text intact
      resolveTerms(terms, selectedOptions);
    }
    else if (input) {
      // Replace selection, if any
      const start = Math.min(input.selectionStart, input.selectionEnd);
      const end = Math.max(input.selectionStart, input.selectionEnd);
      const pre = textFieldValue.substring(0, start);
      const post = textFieldValue.substring(end);
      setTextFieldValue(`${pre}${insertedText}${post}`);
    }
    else {
      setTextFieldValue(insertedText);
    }
  };

  const handleInputChange = (newValue, actionMeta) => {
    const {action, prevInputValue} = actionMeta;
    //console.debug(`input ${action} => ${newValue}`)
    if (action === "input-change") {
      setTextFieldValue(newValue);
      setFetchError(null);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowLeft':
        const caret = e.target.selectionEnd;
        if (caret === 0) {
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      case 'Enter':
        if (!textFieldValue && selectedOptions.length && !isFetchingOptions) {
          e.preventDefault();
          onRunNewSearch();
        }
        // TODO: if options and options not open, open it
        break;
      case 'Escape':
        if (textFieldValue !== "") {
          e.preventDefault();
          setTextFieldValue("");
        }
        else if (selectedOptions.length === 0) {
          e.preventDefault();
          setSelectedOptions(defaultSearchTerms.map(x => entityToOption(x)));
        }
        break;
      case 'u':
        if (e.ctrlKey) {
          if (textFieldValue.length > 0) {
            setTextFieldValue("");
          }
          else {
            reset();
          }
        }
        break;
      default:
        break;
    }
  };

  const getOptionLabel = (option) => {
    return option.label || option.entity['label'] || option.entity.title || option.entity.id;
  };

  // Resolve the option into a string for easy comparison; not the actual value
  const getOptionValue = (option) => option.id || "not-an-option";

  const placeholderText = "Enter a compound, target, pathway, therapeutic indication or other search term";
  const noResultsText = textFieldValue.length < MIN_COMPLETION_LENGTH
    ? "Keep typing for suggestions" : "No results found";

  // Force menu open if fetching options or debugging
  const menuOpenProp = {};
  if (OPTIONS_DEBUG_POPUP || isFetchingOptions || fetchError || textFieldValue !== "") {
    menuOpenProp.menuIsOpen = true;
  }
  else if (termsBeingResolved?.length > 1 || initFromURL) {
    // Force closed if resolving terms (paste multiple or init from URL)
    menuOpenProp.menuIsOpen = false;
  }
  if (selectedOptions.length) {
    selectedOptions[0].onClick = showSelectedOptionsModal;
  }

  const markText = useCallback(text => {
    return markMatchingText(text, textFieldValue);
  }, [textFieldValue]);

  return (
    <>
      <Select
        ref={selectRef}
        {...menuOpenProp}
        autoFocus={true}
        backspaceRemovesValue={true}
        blurInputOnSelect={false}
        className={`autosuggest${fetchError ? ' autosuggest-error' : ''}`}
        classNamePrefix="select"
        closeMenuOnSelect={!OPTIONS_DEBUG_POPUP}
        components={{
          Input: SearchBoxInput,
          LoadingMessage,
          MultiValue,
          MultiValueLabel,
          Option,
          DropdownIndicator: searchButton || components.DropdownIndicator,
        }}
        defaultValue={termsBeingResolved?.length > 0 ? [...selectedOptions, RESOLVING_VALUE] : selectedOptions}
        disabled={termsBeingResolved?.length > 0}
        escapeClearsValue={false}
        filterOption={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={true}
        inputValue={textFieldValue}
        isClearable={true}
        isSearchable={true}
        isLoading={isFetchingOptions || OPTIONS_DEBUG_LOADING}
        isMulti
        markText={markText}
        noOptionsMessage={() => fetchError || noResultsText}
        onInputBlur={() => {}}
        onBlur={() => {}}
        onChange={handleValueChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onSelectResetsInput={false}
        onValueClick={handleValueClick}
        openMenuOnClick={true}
        openMenuOnFocus={false}
        options={options}
        placeholder={placeholderText}
        resolvingTermsMessage={resolvingMessage(termsBeingResolved)}
        searchProps={searchProps}
        shouldKeyDownEventCreateNewOption={() => false}
        tabIndex={1}
        tabSelectsValue={true}
        templates={templates}
        value={termsBeingResolved?.length > 0 ? [...selectedOptions, RESOLVING_VALUE] : selectedOptions}
      />
      <SelectedOptionsDetails
        selectedOptions={selectedOptions}
        isOpen={showSelectedOptions}
        hideModal={handleHideSelectedOptions}
        onItemDelete={handleRemoveSelectedOption}
      />
    </>
  );
}

SearchTermEditor.propTypes = {
  fetchEntities: PropTypes.func,
  fetchSuggestions: PropTypes.func,
  onInputChange: PropTypes.func,
  onNewOptionsObtained: PropTypes.func,
  onRunNewSearch: PropTypes.func,
  onValueChange: PropTypes.func,
  searchIDs: PropTypes.arrayOf(PropTypes.string),
  templates: PropTypes.object,
};

export default SearchTermEditor;

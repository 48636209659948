import React, {useEffect, useState} from "react";
import {Collapse} from "react-bootstrap";
import {renderMarkdown} from "../../markdown/markdown-factory";

const CollapsibleText = ({
                           className = null,
                           isOpen: controllerOpen = false,
                           onToggle : controllerToggle = null,
                           text = "",
                           visibleChars = 160,
                           minimumHidden = 80,
                           markText = null,
                         }) => {

  const [isOpen, setIsOpen] = useState(controllerOpen);
  const onToggle = controllerToggle || (() => setIsOpen(!isOpen));
  let forceOpen = false;

  useEffect(() => {
    setIsOpen(controllerOpen);
  }, [controllerOpen, controllerToggle]);

  text = text.replace(/(\s*<br\/>\s*)+/gi, "\n").trim().replace(/\n/gi, "<br/>");
  if (text && /\w/.test(text[text.length-1])) {
    text += ".";
  }
  const lineCount = text.split("<br/>").length;
  // Break at a space or end of line
  while (visibleChars < text.length && !/\s/.test(text[visibleChars])) {
    ++visibleChars;
  }
  if (text.length - visibleChars < minimumHidden) {
    visibleChars = text.length;
  }
  let visibleText = text.substring(0, visibleChars);
  let hiddenText = text.length > visibleChars ? text.substring(visibleChars) : "";
  const toggleDisplayStyle = {};

  const renderedVisible = renderMarkdown({markdown: visibleText, markText});
  const renderedHidden = renderMarkdown({markdown: hiddenText, markText});

  if (markText) {
    const markedHidden = markText(hiddenText);
    if (markedHidden !== hiddenText) {
      forceOpen = true;
      toggleDisplayStyle.display = "none";
    }
  }
  return (
    <span className={`collapsible-text collapsible${className ? ' ' + className : ''}`}>
      {text.length === 0 ? null : (
        <>
          <span key="visible-part" className="collapsible-visible">{renderedVisible}</span>
          {(hiddenText || "").length === 0 ? null : (
            <span key="hidden-part" className="collapsible-collapsible">
              {!(isOpen || forceOpen) && (<span>&nbsp;...</span>)}
              <Collapse in={isOpen || forceOpen} appear={true}>
                <span className="coll-text">{renderedHidden}</span>
              </Collapse>
              <span className={`collapse-toggler${isOpen || forceOpen ? "" : " collapsible-collapsed"}`}
                    style={toggleDisplayStyle}
                    onClick={onToggle}
              >
                &nbsp;<span title={isOpen || forceOpen ? "Show less" : "Show more"}
                            className={`coll-text-tgl glyphicon glyphicon-chevron-${isOpen || forceOpen ? "up" : "down"}`} />
              </span>
            </span>
          )}
        </>
      )}
    </span>
);
};

export default CollapsibleText;

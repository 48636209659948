import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import MolecularEditorModal from "./MolecularEditorModal";
import molEditorImage from "../img/mol-editor.png";
import SearchTermEditor from "./SearchTermEditor";
import {checkOfflineState} from "./OfflineDetect";
import {inject, observer} from "mobx-react";
import CopyToClipboardButton from "./CopyToClipboardButton";
import {TAXID_HUMAN, TAXID_MOUSE, TAXID_NONE, TAXID_RAT} from "../stores/AppStatusStore";
import ChatModal from "./modals/ChatModal";
import {Threshold} from "./menus/Threshold";
import Checkbox from "./controls/Checkbox";
import {toast} from "react-toastify";
import {isEqual} from "lodash";

import {useModifierKeys} from "../hooks/useModifierKeys";


const ACTIVITIES = ["*", "*-", "EC50", "ED50", "IC50", "ID50", "Kb", "Kd", "Ki", "Potency", "DC50"];

const parseFilter = (xf => {
  if (xf) {
    // <type> <= <threshold> nM
    const parts = xf.split(/\s/);
    if (parts.length === 4 && parts[1] === "<=" && parts[3].toLowerCase() === "nm") {
      const type = parts[0];
      if (new Set(ACTIVITIES.map(s => s.toLowerCase())).has(type.toLowerCase())) {
        try {
          const threshold = parseInt(parts[2]);
          return [type, threshold];
        } catch (e) {
        }
      }
    }
  }
  return [null, null];
});

const SearchButton = (props) => {
  const {children, selectProps, innerProps} = props;
  const {searchProps: {handleRunSearch, isEmpty, isDisabled}} = selectProps;
  return (
    <button
      className={`search-btn${isDisabled ? " disabled" : ""}`}
      onClick={handleRunSearch}
      disabled={isEmpty || isDisabled}
      {...innerProps}
      onMouseDown={e => e.preventDefault()}
      title={isEmpty
        ? "Search terms are required to perform a search"
        : isDisabled
          ? "Change the search terms or one or more search settings to enable a new search"
          : "Submit search with the current terms"}
    >
      <span className="glyphicon glyphicon-search search-icon"/>
      {children}
    </button>
  );
}


const SearchBox = ({
                     className = null,
                     autoFocus = true,
                     searchSessionStore,
                     userStore,
                     appStatusStore,
                     onAIClick = null,
                     aiAvailable = null,
                   }) => {
  const {
    modifySearchParameters,
    runNewSearch,
    fetchSuggestions,
    templates,
    hasFullResults = false,
    chatContext,
    resolvingTerms : termsBeingResolved,
    params: searchParams,
    queuedSearchParams: {queryEntities, xf = null, simSubstructure = null},
    categories,
    awaitingInitialResults,
    hasNewParams,
    authData,
    fetchEntities,
  } = searchSessionStore;

  const {organismRestriction, aiModels = {}} = appStatusStore;
  const setTargetRestriction = (taxID) => {
    appStatusStore.organismRestriction = taxID;
  }

  if (aiAvailable == null) {
    aiAvailable = Object.keys(aiModels || {}).length > 0;
  }
  const disableAI = !aiAvailable;
  const {isAdmin} = userStore;

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showRemainingOptions, setShowRemainingOptions] = useState(true);
  const [hasOptions, setHasOptions] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [isResolving, setIsResolving] = useState(termsBeingResolved?.length > 0);
  const [showAIModal, setShowAIModal] = useState(false);
  const [inputText, setInputText] = useState("");
  const [textToResolve, setTextToResolve] = useState("");

  const [at, ath] = parseFilter(xf);
  const [activityType, setActivityType] = useState(at || "*");
  const [activityThreshold, setActivityThreshold] = useState(ath || 5000);
  const [useActivityFilter, setUseActivityFilter] = useState(at && ath);
  // Separate temporary state flag when all items are deleted
  const [isEmpty, setIsEmpty] = useState(!termsBeingResolved && queryEntities?.length === 0);

  const activeSearchTerms = isEmpty ? [] : queryEntities;

  const toggleAIModal = () => setShowAIModal(!showAIModal);

  useEffect(() => {
    const [type, threshold] = parseFilter(xf);
    if (type && threshold) {
      setActivityType(type);
      setActivityThreshold(threshold);
      setUseActivityFilter(true);
    }
    else {
      //setUseActivityFilter(false);
    }
  }, [xf]);

  useEffect(() => {
    const filter = useActivityFilter ? `${activityType} <= ${activityThreshold} nM` : '';
    if (filter !== xf) {
      modifySearchParameters({xf: filter});
    }
  }, [activityType, activityThreshold, useActivityFilter]);

  const handleResolvingChange = (resolving) => {
    setIsResolving(resolving);
  };
  const handleRunSearch = () => {
    if (!window.navigator.onLine) {
      console.warn("Can't start search: no internet connection");
      return;
    }
    if (searchSessionStore.queuedSearchURL != null) {
      runNewSearch();
    }
    else {
      const warnMsg = inputText
        ? hasOptions
          ? "Please make a selection from autosuggest."
          : "Search term not recognized. Please try another term."
        : "Please enter a search term.";

      toast.warning(warnMsg);
    }
  };

  const handleNewSuggestionsObtained = newOptions => {
    setHasOptions(!!newOptions?.length);
  };

  const handleNewSuggestionsFailure = error => {
    toast.error(`Completion options could not be fetched (${error})`);
  };

  useEffect(() => {
    setIsEmpty(false);
  }, [termsBeingResolved]);

  const handleSearchTermsChange = newValue => {
    if (newValue?.length) {
      modifySearchParameters({queryEntities: newValue?.length ? newValue : null});
      setIsEmpty(false);
    }
    else {
      // Keep local "cleared/tokens deleted" state separate from actual current search params
      setIsEmpty(true);
    }
    setTextToResolve("");
  };

  const handleInputChange = newInput => {
    setInputText(newInput);
  };

  const handleAddTermToResolve = (term, isQuery = false) => {
    modifySearchParameters({simSubstructure: simSubstructure || isQuery})
    setTextToResolve(term);
  }

  const handleReplaceTerm = (term, isQuery = false) => {
    if (!inputText) {
      // Drop the last element
      const qe = queryEntities.slice(0, -2);
      const searchIDs = qe.map(x => x.id);
      modifySearchParameters({queryEntities: qe, searchIDs, simSubstructure: simSubstructure || isQuery})
    }
    setTextToResolve(term);
  }

  const handleMolecularEditorSearch = (term, isQuery = false) => {
    // Automagically set substructure search if using a query molecule
    runNewSearch({searchIDs: [term], simSubstructure: simSubstructure || isQuery});
  };

  const handleEditorClose = () => {
    setShowEditor(false);
  };

  const activeSearchTerm = activeSearchTerms?.length === 1 ? activeSearchTerms[0] : null;
  const molEditorContent = inputText !== ""
                           ? inputText
                           : activeSearchTerm ? activeSearchTerm[activeSearchTerm.novel ? "id" : "smiles"] : "";
  const disableSearch = isEmpty || !hasNewParams || isResolving;
  const disableEditor = false;

  //console.debug(`at=${at} ath=${ath} af=${useActivityFilter}`);
  //console.debug(`xf=${xf} (${searchParams.xf}) me=${minExpansion} (${searchParams.minExpansion})`);

  const [modifiersState, modifiersHandlers] = useModifierKeys(({shift, alt}) => {
    setShowRemainingOptions(!(shift || alt));
  });

  return (
    <div
      className={`search-box${className ? " " + className : ""}`}
      {...modifiersHandlers}
    >
      <SearchTermEditor
        autoFocus={autoFocus}
        fetchSuggestions={fetchSuggestions}
        onValueChange={handleSearchTermsChange}
        onNewOptionsObtained={handleNewSuggestionsObtained}
        onNewOptionsFailure={handleNewSuggestionsFailure}
        onInputChange={handleInputChange}
        onRunNewSearch={handleRunSearch}
        onResolvingChange={handleResolvingChange}
        defaultSearchTerms={queryEntities}
        searchTerms={activeSearchTerms}
        termsBeingResolved={termsBeingResolved}
        textToResolve={textToResolve}
        searchButton={SearchButton}
        searchProps={{isEmpty, handleRunSearch, isDisabled: disableSearch}}
        showRemainingOptions={showRemainingOptions}
        templates={templates}
        title={!isEmpty ? "Start search on current terms" : "Enter one or more terms to search"}
        />
      <button
        className="mol-editor-dlg-btn"
        onClick={checkOfflineState(_ => {
          setShowEditor(true);
        })}
        title="Open chemical drawing tool"
        disabled={disableEditor}
      >
        <img
          src={molEditorImage}
          alt="Open molecular editor"
        />
      </button>
      <Dropdown
        title="Target Lookup Filtering"
        className="dropdown-target-restriction"
        key="target-restriction"
        onToggle={(isOpen) => setShowFilterDropdown(isOpen)}
        show={showFilterDropdown}
      >
        <Dropdown.Toggle><span className="glyphicon glyphicon-filter" /></Dropdown.Toggle>
        <Dropdown.Menu>
          <Threshold
            className={"activity-filter toggle"}
            title={"Filter for at least one activity measurement meeting this criteria"}
            onEnter={() => setShowFilterDropdown(false)}
            checkType={"checkbox"}
            label={(<span>
              Activity
              <select
                defaultValue={"*"}
                value={activityType}
                onChange={(e) => setActivityType(e.target.value)}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation()
                }}
              >
                {ACTIVITIES.sort().map(el => (<option key={el} value={el}>{el === "*" ? "Any" : el === "*-" ? "Any (strict)" : el}</option>))}
              </select> &lt;=
            </span>)}
            min={1} step={100} precision={null}
            value={activityThreshold}
            isSelected={useActivityFilter}
            onValueChange={value => setActivityThreshold(value)}
            onToggle={checked => setUseActivityFilter(checked)}
          ><span> nM</span></Threshold>
          <Dropdown.Header>Target Lookup Restriction</Dropdown.Header>
          <Dropdown.Item
            onClick={() => setTargetRestriction(organismRestriction === TAXID_HUMAN ? TAXID_NONE : TAXID_HUMAN)}
            title="Restrict target suggestions to this species only"
            key="human"
          >
            <Checkbox
              checked={organismRestriction === TAXID_HUMAN}
              label={"Homo Sapiens"}
              title="Restrict target suggestions to this species only"
              onChange={() => setTargetRestriction(organismRestriction === TAXID_HUMAN ? TAXID_NONE : TAXID_HUMAN)}
            />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setTargetRestriction(organismRestriction === TAXID_MOUSE ? TAXID_NONE : TAXID_MOUSE)}
            title="Restrict target suggestions to this species only"
            key="mouse"
          >
            <Checkbox
              checked={organismRestriction === TAXID_MOUSE}
              label={"Mus Musculus"}
              title="Restrict target suggestions to this species only"
              onChange={() => setTargetRestriction(organismRestriction === TAXID_MOUSE ? TAXID_NONE : TAXID_MOUSE)}
            />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => setTargetRestriction(organismRestriction === TAXID_RAT ? TAXID_NONE : TAXID_RAT)}
            title="Restrict target suggestions to this species only"
            key="rat"
          >
            <Checkbox
              checked={organismRestriction === TAXID_RAT}
              label={"Rattus Norvegicus"}
              title="Restrict target suggestions to this species only"
              onChange={() => setTargetRestriction(organismRestriction === TAXID_RAT ? TAXID_NONE : TAXID_RAT)}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {isAdmin &&
        (<CopyToClipboardButton
          className="admin"
          title={hasFullResults ? "Copy all search results to clipboard" : "Wait for search results to finish loading..."}
          disabled={!hasFullResults}
          getText={() => searchSessionStore.clipboardText}
        />)}
      {aiAvailable && (
        <button
          className={"chat"}
          disabled={disableAI}
          title={onAIClick ? "Switch to chat mode" : disableAI ? "Plex LLM unavailable" : "Chat with the Plex LLM"}
          onClick={onAIClick ? onAIClick : toggleAIModal}
        >
          <span className="glyphicon">AI</span>
        </button>
      )}
      <ChatModal
        isOpen={showAIModal}
        onHide={toggleAIModal}
        initialInput={chatContext}
        auth={authData}
        searchParams={searchParams}
        categories={categories}
        showToolResults={false}
        useTools={false}
        fetchEntities={fetchEntities}
        waitingForResults={awaitingInitialResults}
      />
      <MolecularEditorModal
        showEditor={showEditor}
        onCloseEditor={handleEditorClose}
        encoding={molEditorContent}
        onSearchFromEditor={handleMolecularEditorSearch}
        onAddTerm={handleAddTermToResolve}
        onReplaceTerm={handleReplaceTerm}
        forceSubstructureQuery={simSubstructure}
      />
    </div>
  );
};

export default inject("appStatusStore", "userStore", "searchSessionStore")(observer(SearchBox));

import React from "react";
import PropTypes from "prop-types";
import FilterButton from "../../FilterButton";
import {SubmitSearchButton} from "../../SubmitSearchButton";

const SearchResultFooter = ({
  id,
  dsConfig,
  directConnections,
  neighborhood,
  neighborhoodSize,
  onFilterSelection,
  currentCategory
}) => {
  return (
    <div className="sr-links search-item-footer">
      <FilterButton
        label={`Direct Connections (${directConnections})`}
        title={`${directConnections} directly connected items`}
        onClick={() => onFilterSelection("dc", id)}
        disabled={directConnections === 0}
      />
      <FilterButton
        label={`Neighborhood ${neighborhood !== -1 ? neighborhood : "(isolated)"} (${neighborhoodSize})`}
        title={`Cluster of ${neighborhoodSize} members`}
        onClick={() => onFilterSelection("nbr", neighborhood)}
        disabled={neighborhood === -1}
      />
      <SubmitSearchButton ids={[id]} dsConfig={dsConfig} category={currentCategory} />
    </div>
  );
}

SearchResultFooter.propTypes = {
  onFilterSelection: PropTypes.func.isRequired,
  currentCategory: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default SearchResultFooter;

import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {toJS} from "mobx";
import {toast} from "react-toastify";
import {fetchChatCompletion, fetchHelp} from "../queries/autosuggest";
import LoadingAnimation from "./LoadingAnimation";
import Textarea from 'react-expanding-textarea';
import {DEV, getConfig} from "../lib/configMgr";
import {useTypewriter} from "../hooks/useTypewriter";
import {useSSEListener} from "../hooks/useSSEListener";
import Avatar from "./Avatar";
import CopyToClipboardButton from "./CopyToClipboardButton";
import {
  blunt,
  extractSearchParams,
  invokeTool,
  TOOL_MEMBERS,
  TOOL_RESOLVE,
  TOOL_SEARCH,
  TOOL_RESOLVE_AGENT,
  TOOL_SEARCH_AGENT
} from "../tools";
import {renderMarkdown} from "../markdown/markdown-factory";
import SubmitSearchButton from "./SubmitSearchButton";
import {ChatSettings} from "./ChatSettings";
import SearchContext from "../lib/SearchContext";
import _ from "lodash";
import {useDebounce, useThrottle} from "../hooks/useDebounce";
import {isImage, isPlexID} from "../lib/utils";
import {useInterval} from "../hooks/useInterval";
import SimpleSelect from "./controls/SimpleSelect";
import FileAttachments from "./controls/FileAttachments";
import {prepareMarkdown, escapeMD} from "../markdown/utils";

const { ExternalRESTAPI, env } = getConfig();
const WELCOME = "Welcome to the Plex LLM!";
const INPUT_STORAGE_KEY = "chat-prompt";
const HELP_STORAGE_KEY = "chat-help"

const DEBUG_STREAM = false;

// Suffixes recognized by bedrock; assume text if not in one of these
// Claude also accepts odt, rtf, epub, and json; pass these as "txt"
// See https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_Message.html
// See https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_DocumentBlock.html
// See https://docs.aws.amazon.com/bedrock/latest/APIReference/API_runtime_ImageBlock.html
const FILE_INPUT_ACCEPT = [
  ".pdf", "application/pdf",
  ".csv", "text/csv",
  ".doc", "application/msword",
  ".docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".xls", "application/vnd.ms-excel",
  ".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".html", "text/html",
  ".txt", "text/plain",
  ".md", "text/markdown",
  ".png", "image/png",
  ".jpeg", "image/jpeg",
  ".gif", "image/gif",
  ".webp", "image/webp",
];
const MIME2FORMAT = {
  "application/pdf": "pdf",
  "text/csv": "csv",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "text/html": "html",
  "text/plain": "txt",
  "text/markdown": "md",
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpg": "jpeg",
  "image/gif": "gif",
  "image/webp": "webp",
};
const DOC_LIMIT = 5;
const DOC_SIZE_LIMIT = 4.5 * 1024 * 1024;
const IMAGE_LIMIT = 20;
const IMAGE_SIZE_LIMIT = 3.75 * 1024 * 1024;

const decorateAssistantText = (s) => {
  if (!s) {
    return "";
  }
  // Simply replacing words can result in some really odd sentences...
  //return s.replace(/(anthropic|openai)/gi, "Plex").replace(/(claude|chatgpt)/gi, "Plexy");
  return s;
};


export const fixLLMMarkdown = (s) => {
  // Make assistant-generated content look proper when passed to markdown-to-jsx
  const patched = s.trim()
    // Bug in markdown-to-jsx renders /^[- ]/ as part of _any_ preceding list
    // Two spaces at the end of a line indicate the newline is to be preserved,
    // so inject that whenever we encounter a standalone newline.
    // Cases to ignore:
    // \n\n (multiple newlines)
    // _\n (newline preceded by one a space)
    // \n[ (reference-style link definitions)
    .replace(/(?<![ \n])\n(?![[\n])/gm, "  \n").replace(/(\n\n)\n+/gm, "$1")
    .replace(/^(- )/gm, " $1")
    // Bug in markdown-to-jsx fails to render numbered lists unless preceded by two newlines
    .replace(/:\n1\./g, ":$1\n\n1.")
    // Put <llm-training> _outside_ of lists
    .replace(/^(1\.\s*)<llm-training>/mg, "<llm-training>$1")
  return prepareMarkdown(patched);
};

const renderLLMMarkdown = (output) => {
  const LLM_MARKDOWN_OVERRIDES = {
    ["llm-training"]: null,
  };
  const markdown = fixLLMMarkdown(blunt(output));
  const overrides = {
    ["llm-training"]: {
      component: ({children}) => (<span className={"llm-training"} >{children}</span>),
    },
  };
  return renderMarkdown({markdown, overrides})
};


const canonicalMessages = messages => {
  return messages
    .map(({role, content}) => ({role: role === "tool" ? "user" : role, content}))
    .filter(({role}) => role === "user" || role === "assistant");
};

export const ChatSession = ({className = null, userStore, appStatusStore, onSubmit = null, showWelcome = false,
                              autoFocus = false, onSearchClick = null, onConversationActive = null,
                              searchParams = null, categories = null, showSettings = true,
                              useTools = false, showToolResults = true, useMarkdown = true,
                              fetchEntities = null, waitingForResults = false,
                              allowAttachments = false}) => {

  const [sessionID, setSessionID] = useState(null);
  const helpOnly = !useTools && !searchParams;
  // Preserve chat context for the duration of the app status store; distinguish between chat contexts
  // help, guide, and agents
  const settingsKey = helpOnly
                     ? HELP_STORAGE_KEY
                     : `chat-session${sessionID ? '-' + sessionID : ''}-${JSON.stringify(searchParams)}`;

  const {aiModels = {}, aiTemplates = {}, dsConfig = 0} = appStatusStore;
  const {isAdmin, showAdmin, authData: auth} = userStore;
  const initialInput = helpOnly ? "" : localStorage[INPUT_STORAGE_KEY] || "";
  const [interrupted, setInterrupted] = useState(false);

  const inputRef = useRef();
  const convoTailRef = useRef();

  const [expandedSourceLinks, setExpandedSourceLinks] = useState({});
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const streamResponse = DEV && DEBUG_STREAM;
  const [convo, setConvo] = useState(toJS(appStatusStore.settings.get(settingsKey, []) || []));
  const chatMessages = useMemo(() => canonicalMessages(convo), []);
  const convoStarted = convo.length > 0;
  const [inputText, setInputText] = useState(initialInput);
  const [streamURL, setStreamURL] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [chatSettings, setChatSettings] = useState({});
  // Allow async methods to dynamically check for user interrupt
  const interruptRef = useRef();
  // Allow async methods to dynamically check for current message
  const pendingChatRequestRef = useRef();
  const pendingToolUseRequestsRef = useRef();
  const [typingElement, setTypingElement] = useState(null);
  const pendingIDLookups = useMemo(() => new Set(), []);
  const failedIDLookups = useMemo(() => new Set(), []);
  const editedTemplates = useMemo(() => ({}), []);
  const [selectedTemplate, setSelectedTemplate] = useState("extra-prompt");
  const [templateText, setTemplateText] = useState(aiTemplates['extra-prompt']);
  const [userIsScrolling, setUserIsScrolling] = useState(false);
  const [typewriterText, startTyping] = useTypewriter();
  const convoRef = useRef();
  const [convoHeight, setConvoHeight] = useState(null);
  const [scrollTop, setScrollTop] = useState(null);
  const [portHeight, setPortHeight] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const showConversation = onSubmit == null && convo.length > 0;
  const userInputPlaceholder = convoStarted
                               ? (waitingForResponse ? "Plexy is thinking..." : "Reply to Plexy...")
                               : searchParams
                                 ? (waitingForResults ? "Waiting for search results..." : "Discuss the search results")
                                 : helpOnly
                                   ? "How may I help you?"
                                   : "Ask a question, start an investigation, or learn about Plex";

  useEffect(() => {
    setTemplateText(editedTemplates[selectedTemplate] || aiTemplates[selectedTemplate]);
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateText !== aiTemplates[selectedTemplate]) {
      editedTemplates[selectedTemplate] = templateText;
    }
    else {
      delete editedTemplates[selectedTemplate]
    }
  }, [templateText]);

  useEffect(() => {
    interruptRef.current = interrupted;
  }, [interrupted]);

  const updateScrollState = useThrottle(() => {
    if (convoRef.current) {
      const {scrollTop = 0, scrollHeight = null, clientHeight = null} = convoRef.current;
      if (scrollHeight != null && clientHeight != null) {
        setConvoHeight(scrollHeight);
        setScrollTop(scrollTop);
        setPortHeight(clientHeight);
      }
    }
  });

  useEffect(() => {
    // When scrolled completely to bottom, scrollTop + portHeight == convoHeight
    setUserIsScrolling(scrollTop + portHeight !== convoHeight);
  }, [convoHeight, scrollTop, portHeight])

  useEffect(() => {
    updateScrollState();
  }, [typewriterText]);

  const resolveSourceLinks = missing => {
    if (fetchEntities) {
      // Keep track of individual failures and don't retry
      const activeLookups = new Set(missing);
      fetchEntities(missing)
        .then(entities => {
          if (convo.length === 0) return;
          const updated = entities.reduce((result, el) => {
            if (el.links?.length) {
              result[el.id] = el.links[0].url;
            }
            else {
              failedIDLookups.add(el.id);
            }
            activeLookups.delete(el.id);
            pendingIDLookups.delete(el.id);
            return result;
          }, {});
          setExpandedSourceLinks((prev) => ({...prev, ...updated}));
          Array.from(activeLookups).forEach(id => failedIDLookups.add(id));
        })
        .catch(error => {
          if (convo.length === 0) return;
          console.warn("Failed to resolve some markdown links", missing, error);
        });
    }
  };

  const expandSourceLinks = (s) => {
    const missing = [];
    const result = s.replace(/(?<=[^\\]]\()([^)]+)(?=\))/g, (match, id) => {
      if (expandedSourceLinks[id]) {
        return `${expandedSourceLinks[id]} "${id}"`;
      }
      if (!failedIDLookups.has(id)) {
        missing.push(id);
      }
      return id;
    });
    if (missing.length) {
      const unfetched = missing.filter(id => !pendingIDLookups.has(id) && !failedIDLookups.has(id));
      if (unfetched.length > 0) {
        unfetched.forEach(id => pendingIDLookups.add(id));
        resolveSourceLinks(unfetched);
      }
    }
    return result;
  };

  useEffect(() => {
    // unless user is scrolling, scroll to bottom on new output
    if (!userIsScrolling) {
      scrollToBottom(true);
    }
  }, [convoHeight, userIsScrolling]);

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    appStatusStore.settings.set(settingsKey, convo || []);
    onConversationActive && onConversationActive(convo.length > 0);
  }, [convo]);

  const queueChatRequest = (request) => {
    if (request && pendingChatRequestRef.current && pendingChatRequestRef.current !== request) {
      console.error("Overwriting previous request before last has completed",
                    pendingChatRequestRef.current, request);
    }
    pendingChatRequestRef.current = request;
    if (request) {
      sendChatMessage(request);
    }
  };

  const queueToolUseRequests = (requests) => {
    const unresolved = pendingToolUseRequestsRef.current;
    pendingToolUseRequestsRef.current = requests;
    if (requests) {
      if (unresolved && unresolved !== requests) {
        console.error("Overwriting previous tool use request before last has completed",
                      unresolved, requests);
      }
      setWaitingForResponse(true);
      processToolUseRequests(requests).then(handleToolUseCompletion);
    }
  };

  const sendChatMessage = (chatMessage) => {
    setWaitingForResponse(true);
    scrollToBottom(true);
    let promise = null;
    chatMessages.push({...chatMessage, role: "user"});
    if (helpOnly) {
      promise = fetchHelp(chatMessage.content.map(el => el.text || "").join(""), sessionID && `${sessionID}:${chatMessages.length - 1}`);
    }
    else {
      if (!chatMessages.every((el, idx) => el.role === (idx % 2 === 0) ? "user" : "assistant")) {
        throw new Error("Badly formatted messages")
      }
      promise = fetchChatCompletion(searchParams || {},
                                    {
                                      ...chatSettings,
                                      stream: streamResponse,
                                      messages: chatMessages,
                                      templates: editedTemplates,
                                      useMarkdown,
                                      useTools,
                                      role: "guide",
                                    }, auth)
    }
    setConvo(convo => [...convo, chatMessage]);
    promise
      .then(result => {
        if (pendingChatRequestRef.current === chatMessage) {
          queueChatRequest(null);
          handleChatCompletion(result);
        }
        else {
          console.warn("Ignore obsolete chat response", chatMessage, pendingChatRequestRef.current);
        }
      })
      .catch(e => {
        if (pendingChatRequestRef.current === chatMessage) {
          queueChatRequest(null);
          handleChatCompletionError(e, "system");
        }
        else {
          console.warn("Ignore obsolete error", e, chatMessage, pendingChatRequestRef.current);
        }
      })
      .finally(scrollToBottom);
  };

  useEffect(() => {
    if (!waitingForResponse) {
      const id = setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
      return () => clearTimeout(id);
    }
  }, [waitingForResponse])

  const handleToolUseCompletion = (requests, interrupted = false) => {
    console.debug("handleToolUseCompletion", requests);
    // Ignore obsolete responses
    if (requests && pendingToolUseRequestsRef.current && !_.isEqual(pendingToolUseRequestsRef.current, requests)) {
      console.log("Ignore obsolete tool results", pendingToolUseRequestsRef.current, requests);
      return;
    }
    setWaitingForResponse(false);
    pendingToolUseRequestsRef.current = null;
    let stopReason = "turn_end";
    const toolResults = [];
    const toolUseInfo = {};
    requests.forEach(request => {
      const {result = null, ...toolUse} = request;
      const error = result?.error || request.error;
      stopReason = interrupted ? "user_interrupt" : result?.stop_reason;
      const resultBlock = {};
      //console.debug(`handleToolUseCompletion (${toolUse.name})`, result);
      toolUseInfo[toolUse.toolUseId] = toolUse;
      if (interrupted) {
        console.warn("User interrupt");
        resultBlock.text = `
The user has more input.  Finish your current response and end your turn.  Do not generate additional tool use requests.
Acknowledge receipt of this instruction by saying 'Do you have something to add?'    
`;
        stopReason = "user_interrupt";
      }
      else if (error) {
        console.error(`Tool use resulted in an error ${error}`);
        resultBlock.text = `Error: ${error}`;
        stopReason = "tool_error";
      }
      else if (toolUse.name === TOOL_SEARCH_AGENT
               || toolUse.name === TOOL_SEARCH
               || toolUse.name === TOOL_RESOLVE_AGENT) {
        const {role, search_id: searchID} = result;
        // search/agent results include internal messaging blocks in "content"
        // include these in the conversation, but change their roles
        // so that they are ignored in the main conversation and optionally hidden in the UI
        const agentMessages = result?.content;
        setConvo(convo => [
          ...convo, ...injectToolUseInfo(agentMessages)
            .map(el => ({...el, role: el.role === "user" ? `${role}-result` : role}))
        ]);
        const lastMsg = agentMessages[agentMessages.length - 1]
        resultBlock.json = {text: lastMsg.content.map(el => el.text || "").join(""), searchID};
      }
      else if (toolUse.name === TOOL_RESOLVE) {
        const MAX_AS_RESULTS = 500
        const reduced = toolUse.input.terms.length === 1 ? result?.slice(0, MAX_AS_RESULTS) : result;
        // Flatten the data and avoid recursion or duplication of options data
        // Be conservative in what is passed on to the LLM to avoid flooding its context buffer
        const prune = ({entity = null, ...option}) => {
          if (entity) {
            const {dataset = null, inchi = null, inchikey = null, iupac = null, source_id = null, ...pruned} = entity;
            return {
              ...pruned,
              match_term: option['match-term'],
              match_type: option['match-type'],
              match_score: option['match-score']
            };
          }
          return {"error": `Could not resolve term '${option['match-term']}'`};
        }
        resultBlock.json = {"options": reduced?.map(option => prune(option))};
      }
      else {
        resultBlock.json = result;
      }
      const contentBlock = {toolResult: {toolUseId: toolUse.toolUseId, content: [resultBlock]}};
      if (error) {
        // NOTE: Only Claude supports the 'status' field
        contentBlock.toolResult.status = "error";
      }
      toolResults.push(contentBlock)
    });

    // Attach tool use info to the tool results message
    // so that we can refer to input parameters and tool name
    queueUserMessageContent(toolResults,"tool", toolUseInfo, stopReason);
  };

  const handleStreamingResponse = useCallback(event => {
    // FIXME stream handling not tested, mostly out of date
    //console.log(`handleStreamingResponse ${convo.length}`);
    setWaitingForResponse(false);
    const msg = JSON.parse(event.data);
    const mtype = msg.type || event.type;
    //console.log(`SSE data received`, event);
    // NOTE: these streaming message types are specific to Anthropic's streaming API
    if (mtype === "message_start") {
      //{"type": "message_start","message": {"id": "msg_01GnRNG1psEzTMECgZr32tiv","content": [],"model": "claude-3-sonnet-28k-20240229","role": "assistant","stop_reason": null,"stop_sequence": null,"type": "message","usage": {"input_tokens": 11414,"output_tokens": 1}}}
      setConvo(convo => [...convo, {role: msg.role || "assistant", content: [{text: ""}]}]);
    }
    else if (mtype === "content_block_start") {
      // {"content_block": {"text": "", "type": "text"}, "index": 0, "type": "content_block_start"}
      if (msg.content_block?.text) {
        console.warn("Unexpected content in content_block_start")
      }
    }
    else if (mtype === "content_block_delta") {
      // {"delta": {"text": " that", "type": "text_delta"}, "index": 0, "type": "content_block_delta"}
      if (msg.delta?.text) {
        setConvo(convo => {
          const last = convo[convo.length - 1];
          last.content[0].text += msg.delta.text;
          return [...convo];
        });
      }
    }
    else if (mtype === "content_block_stop") {
      // {"type": "content_block_stop", "index": 0}
      setConvo(convo => {
        const last = convo[convo.length - 1];
        if (msg.tool_use) {
          queueToolUseRequests(msg.tool_use);
          last.toolUseRequests = msg.tool_use;
          return [...convo];
        }
        return convo;
      });
    }
    else if (mtype === "message_delta") {
      //{"type": "message_delta","delta": {"stop_reason": "end_turn","stop_sequence": null},"usage": {"output_tokens": 62}}
    }
    else if (mtype === "message_stop") {
      //{"type": "message_stop","amazon-bedrock-invocationMetrics": {"inputTokenCount": 11414,"outputTokenCount": 62,"invocationLatency": 5665,"firstByteLatency": 3598}}
      setIsStreaming(false);
      setStreamURL(null);
    }
    else if (mtype === "ping") {
    }
    else if (mtype === "error"){
      toast.error(`LLM error: ${msg.error}`);
      console.error(`Error streaming LLM response: ${msg.error}`);
      setIsStreaming(false);
      setStreamURL(null);
    }
  }, [streamURL]);

  const handleStreamError = useCallback((e) => {
    console.error(`Streaming error`, e);
    setIsStreaming(false);
    setStreamURL(null);
    appendConvoError("Connection lost", "assistant", e);
  }, [streamURL]);

  const clearFailedIDs = useDebounce(() => {
    //console.log("Clearing failed IDs to retry lookup", failedIDLookups);
    Array.from(failedIDLookups)
      .filter(id => isPlexID(id))
      .forEach(id => failedIDLookups.delete(id));
    //console.log("Cleared failed IDs to retry lookup", failedIDLookups);
  }, 30000, {leading: false, trailing: true});

  useInterval(() => clearFailedIDs(), 30000);

  const scrollToBottom = (requestFocus = false)=> {
    setTimeout(() => {
      convoTailRef?.current?.scrollIntoView({behavior: "smooth"});
      if (requestFocus) {
        inputRef?.current?.focus();
      }
    }, 0);
  };

  useSSEListener({url: streamURL, onData: handleStreamingResponse, onError: handleStreamError});

  const inputChanged = (e) => {
    const text = e.target.value || "";
    setInputText(text);
  };

  const clearMessages = () => {
    setWaitingForResponse(false);
    queueChatRequest(null);
    queueToolUseRequests(null);
    setSessionID(null);
    setConvo(convo => convo.length === 0 ? convo : []);
    chatMessages.length = 0;
  };

  const injectToolUseInfo = (messages = []) => {
    // Return a map of tool use ID => tool Use
    const toolUseInfo = messages.reduce((result, el) => {
      el.content.filter(b => b.toolUse).forEach(b => {
        result[b.toolUse.toolUseId] = b.toolUse;
      })
      return result;
    }, {});
    // Attach the map to each message
    return messages.map(el => ({...el, toolUseInfo}));
  };

  const handleChatResponse = (msg) => {
    const toolUseRequests = msg.content?.filter(el => el.toolUse).map(el => ({...el.toolUse})) || [];
    chatMessages.push(msg);
    if (toolUseRequests.length && !interruptRef.current) {
      queueToolUseRequests(toolUseRequests);
    }
    const text = msg.content.map(el => el.text || "").join("");
    setTypingElement(msg);
    startTyping(text, () => setTypingElement(null));
    setConvo(convo => [...convo, msg]);
    if (toolUseRequests.length && interruptRef.current) {
      sendUserInterrupt(toolUseRequests);
    }
    else {
      interruptRef.current = false;
      setInterrupted(false);
    }
  };

  const handleChatCompletion = resp => {
    setWaitingForResponse(false);
    if (helpOnly) {
      const {response: text, session_id} = resp;
      const msg = {role: "assistant", content: [{text}]};
      setSessionID(session_id);
      handleChatResponse(msg);
    }
    else {
      const {
        role,
        content = null,
        stream: streamID = null,
        error = null,
        stop_reason: stopReason = null,
        system_prompt: systemPrompt = null,
        ...adminContent
      } = resp;
      const {
        usage: {inputTokens = 0, outputTokens = 0, totalTokens = 0} = {},
        metrics: {latencyMs = null} = {},
      } = adminContent;
      if (streamID) {
        setIsStreaming(true);
        setStreamURL(`${ExternalRESTAPI.Stream}?channel=${streamID}`);
      } else if (error) {
        console.error(`Chat completion error`, error);
        appendConvoError(error, "system", error);
      } else {
        let msg = {role, content, stopReason};
        if (role === TOOL_SEARCH_AGENT || role === TOOL_SEARCH || role === TOOL_RESOLVE_AGENT) {
          // When talking to the search/agent tools, all newly generated messages
          // (including tool use) are returned in "content"
          const updates = content.slice(0, content.length - 1);
          setConvo(convo => [...convo, ...injectToolUseInfo(updates)]);
          msg = content[content.length - 1];
        }
        handleChatResponse(msg);
      }
    }
  };

  const appendConvoError = (text, role = "system", error = null) => {
    console.error(`${role} error`, error, convo);
    setWaitingForResponse(false);
    setConvo(convo => {
      return [...convo, {role, status: 'error', error, "content": [{text: text || "Chat error"}]}];
    });
  };

  const handleChatCompletionError = (error, role = "assistant") => {
    const text = typeof(error) === "string"
                 ? error
                 : error instanceof Response
                   ? `${error.status}: ${error.statusText}`
                   : error?.message
                     ? error.message
                     : error?.content
                       ? error.content.map(el => el.text || "").join("")
                       : JSON.stringify(error);
    appendConvoError(text, role, error);
  };

  const formatDocumentContentBlock = (file) => {
    // The attachment will actually be injected server-side from a file upload cache
    const format = MIME2FORMAT[file.type];
    if (!format) {
      console.warn(`File type ${file.type} may not be supported`);
    }
    // Keep a simple object copy of the File object
    const _file = {name: file.name, type: file.type, size: file.size, lastModified: file.lastModified};
    if (isImage(file)) {
      return {image: {format: format || "png", source: {file: _file}}};
    }
    return {document: {format: format || "txt", name: file.name, source: {file: _file}}};
  };

  const formatTextContentBlock = (text) => {
    return {text: text.trim()};
  }

  const queueUserMessageContent = (content, role = "user", toolUseInfo = null, stopReason = null) => {
    // Avoid directly referring to local state variables (e.g. convo, interrupted)
    // and use useEffect to trigger actual actions based on lastChatRequest
    const userMessage = {role, content, toolUseInfo, stopReason};
    // user messages and tool results require an up-to-date message history, so trigger within the state update
    queueChatRequest(userMessage);
  };

  const queueUserTextWithAttachments = text => {
    const content = []
    content.push(formatTextContentBlock(text))
    attachments.forEach(f => {
      content.push(formatDocumentContentBlock(f));
    });
    setInputText('');
    setAttachments(_ => []);
    queueUserMessageContent(content);
  }

  const handleSendClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const text = inputText.trim();
    if (text) {
      queueUserTextWithAttachments(text);
    }
  }

  const handlePaste = (e) => {
    try {
      const text = e.clipboardData.getData('Text');
      const parsed = JSON.parse(text);
      // If it renders, it's probably a valid conversation
      renderConversation(parsed);
      setConvo(parsed);
      chatMessages.splice(0, chatMessages.length, ...canonicalMessages(parsed));
      e.preventDefault();
      e.stopPropagation();
    }
    catch(e) {
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !(e.shiftKey || e.altKey)) {
      e.stopPropagation();
      e.preventDefault();
      const text = inputText.trim();
      if (text && !waitingForResponse && !waitingForResults) {
        if (onSubmit) {
          onSubmit(text);
        }
        else {
          queueUserTextWithAttachments(text);
        }
      }
    }
  };
  const sendUserInterrupt = (requests) => {
    // Insert an "interrupt" response to any pending tool use requests
    pendingToolUseRequestsRef.current = requests;
    handleToolUseCompletion(requests, true);
  };
  // This gets kinda complicated when continuing a conversation, so we're disabling user interrupts for now
  const handleInterrupt = () => {
    // Handle these two cases
    // A) middle of tool use, ignore pending tool results and respond immediately
    // B) responding to user request, must wait for a response before stopping
    setInterrupted(true);
    if (pendingToolUseRequestsRef.current) {
      const requests = pendingToolUseRequestsRef.current;
      queueToolUseRequests(null);
      sendUserInterrupt(requests);
    }
  };
  const extractClipboardText = (role, content) => {
    const includeToolUse = isAdmin && showAdmin;
    return content.map(contentBlock => {
      const {text = null, toolUse = [], toolResult = null, json = null} = contentBlock;
      const toolName = toolUse?.name;
      // Ignore these, they're not interesting
      if (toolName === TOOL_MEMBERS || toolName === TOOL_RESOLVE) {
        return "";
      }
      if (role === "assistant") {
        if (toolName === TOOL_SEARCH_AGENT || toolName === TOOL_SEARCH) {
          const url = SearchContext.getAppSearchURL(extractSearchParams(toolUse, dsConfig, {withLabels: true}));
          return `Plexy: ${toolUse.input.instructions}\n\n${url}\n\n`;
        }
        return includeToolUse ? `Plexy: ${text || toolName}\n\n` : "";
      }
      if (toolResult) {
        if (toolResult.content[0]?.json?.options) {
          return `Resolved IDs: ${toolResult.content[0].json.options.filter(el => el).map(el => el.id).join(", ")}\n\n`;
        }
        return includeToolUse ? extractClipboardText("Result", toolResult.content) : "";
      }
      return `${role === "user" ? "User" : role === "system" ? "System" : role}: ${text || JSON.stringify(contentBlock)}\n\n`;
    }).join("");
  };
  const getClipboardContents = useCallback((full = false) => {
    // Replicate visible entries as much as possible
    if (full) {
      return JSON.stringify(convo);
    }
    const text = convo
      .reduce((result, msg) => `${result}${extractClipboardText(msg.role, msg.content)}`, "");
    return expandSourceLinks(text);
  }, [convo, expandSourceLinks]);

  useEffect(() => {
    if (!helpOnly) {
      localStorage[INPUT_STORAGE_KEY] = inputText;
    }
  }, [inputText]);

  const processToolUseRequests = requests => {
    // Obtain tool use results for a list of tool use requests
    // Cf. sendChatMessage
    // Allow parallel invocation
    requests?.forEach(r => {delete r.result;delete r.error; delete r.status});
    return Promise.all(requests.map(request => {
      //console.debug(`Invoke ${request.name} => ${JSON.stringify(request.input)}`);
      return invokeTool(request, {dsConfig, templates: editedTemplates, auth})
        .then(result => {request.result = result; return request;})
        .catch(error => {
          request.error = error;
          return request;
        })
    }));
  };

  const discardConversation = (idx = 0)=> {
    pendingChatRequestRef.current = null;
    pendingToolUseRequestsRef.current = null;
    const userInput = convo[idx]['content'].map(el => el.text || '').join('\n');
    setConvo(convo.slice(0, idx));
    chatMessages.splice(idx, chatMessages.length);
    setInputText(userInput);
    scrollToBottom(true);
    if (idx === 0) {
      setSessionID(null);
    }
  };

  const retype = (idx = 0) => {
    if (idx < convo.length) {
      const msg = convo[idx];
      setTypingElement(msg);
      startTyping(msg.content.map(el => el.text || "").join(""), () => setTypingElement(null));
    }
  };

  const replayConversation = (idx = 0)=> {
    // idx is relative to convo, not messages
    const msg = convo[idx];
    interruptRef.current = false;
    pendingChatRequestRef.current = null;
    pendingToolUseRequestsRef.current = null;
    setInterrupted(false);
    setConvo(convo => convo.slice(0, idx));
    chatMessages.splice(0, chatMessages.length, ...canonicalMessages(convo.slice(0, idx)));
    if (msg.role === "user") {
      queueUserMessageContent(msg.content);
    }
    else if (msg.stopReason === "tool_use") {
      handleChatResponse(msg);
    }
  };

  const renderConversation = (turns) => {
    //console.log("Render conversation", turns);
    return turns.map((msg, idx) => {
      const {
        role = "user",
        content = [],
        toolUseInfo = null,
        usage: {inputTokens = null, outputTokens = null, totalTokens = null} = {},
      } = msg;
      const stopReason = msg?.stop_reason || msg?.stopReason || "<message error>";
      const tokenUsage = isAdmin ? ` ${inputTokens}/${outputTokens}/${totalTokens}` : "";
      const isAgent = role === TOOL_SEARCH_AGENT || role === TOOL_RESOLVE_AGENT || role === TOOL_SEARCH;
      const isToolResult = role.indexOf("tool-result") !== -1;
      if (isAgent || isToolResult) {
        return isAdmin && (<div key={`${role}-${idx}`} className={`admin ${role} ${isAgent ? "agent" : "tool-result"} turn`}>
          <div key={"text"} title="Agent response" >
            {renderLLMMarkdown(content.filter(el => el.text).map(el => el.text || '').join('\n'))}
            {stopReason === "max_tokens" ? (<span className="truncated">(truncated response{tokenUsage})</span>) : ""}
          </div>
          <div key={"tool-use"} title="Agent tool use details" className={`admin ${role}-tool-use tool-use`} >
            {content.filter(el => el.toolUse)
              .map((el, key) => (<em key={key}>{el.toolUse.name} {JSON.stringify(el.toolUse.input)}<br/></em>))}
          </div>
          <div key={"tool-result"} title="Agent tool use result" className={`admin ${role}-tool-result tool-result`}>
            {content.filter(el => el.toolResult)
              .map((el, key) => el.toolResult.content
                .map((el, ckey) => (
                  <span key={`${key}_${ckey}`} title={JSON.stringify(el.json)} >
                    {JSON.stringify(el.json).slice(0, 64)}...<br/>
                  </span>
                )))}
          </div>
        </div>);
      }
      const blocks = content?.map((contentBlock, cidx) => {
        const {text = "", toolUse = null, toolResult = null, document = null, image = null} = contentBlock;
        const {status = null} = toolResult || msg;
        const errorClass = status === "error" ? " alert alert-error" : "";
        const key = `${idx}.${cidx}`;
        if (!text && !toolUse && !toolResult) {
          return null;
        }
        if (toolUse) {
          if (toolUse.name === TOOL_RESOLVE || toolUse.name === TOOL_MEMBERS) {
            return isAdmin && (
              <div key={`tool-use-${key}`}
                   title="The guide is requesting these terms be resolved into Plex IDs"
                   className={`${role} admin tool-use turn`}>
                <em>{toolUse.name}</em> request {JSON.stringify(toolUse.input)})
              </div>
            );
          }
          if (toolUse.name === TOOL_SEARCH_AGENT || toolUse.name === TOOL_SEARCH) {
            const searchParams = extractSearchParams(toolUse, dsConfig);
            const instructions = blunt(toolUse.input.instructions || '');
            const titleExtra = text ? `\n\n${instructions}` : '';
            if (typeof(toolUse.input.ids) === "string") {
              toolUse.input.ids = toolUse.input.ids.split(",");
            }
            return (
              <Fragment key={`fragment-${key}`}>
                {isAdmin && (
                  <div key={`tool-use-${key}`}
                       title="Details of the tool use requested by the guide"
                       className={`${role} turn-${key} admin${errorClass} tool-use turn`} >
                    <em>{toolUse.name}</em> {JSON.stringify(toolUse.input)})
                  </div>
                )}
                <div key={`tool-use-text-${key}`}
                     title="Instructions from the guide to a sub-agent"
                     className={`${role} turn-${key}${errorClass} tool-use tool-use-text turn`} >
                  {renderLLMMarkdown(instructions)}
                </div>
                <SubmitSearchButton
                  key={`search-button-${key}`}
                  className={`button btn turn-${key}`}
                  searchParams={searchParams}
                  title={`Click to view search results in detail${titleExtra}`}
                  terms={{terms: toolUse.input.ids || [], maxItems: 5}}
                  fetchEntities={fetchEntities}
                />
              </Fragment>
            )
          }
          else {
            // Miscellaneous agent tool use
            return isAdmin && (
              <div key={`tool-use-${key}`} className={`${role} admin tool-use turn`}>
                <em>{toolUse.name}</em> request {JSON.stringify(toolUse.input)})
              </div>
            );
          }
        }
        if (role === "assistant") {
          const blockText = typingElement === msg ? typewriterText : text;
          const markdown = expandSourceLinks(blunt(blockText), expandedSourceLinks);
          const isToolUseRequest = msg.stopReason === "tool_use";
          return markdown && (
            <div key={`plexy-${key}`} className={`${role} turn-${key} turn${errorClass}`}>
              {renderLLMMarkdown(markdown)}
              {stopReason === "max_tokens" ? (<span className="truncated">(truncated response{tokenUsage})</span>) : ""}
              <span
                key={`retype-${key}`}
                className={`admin glyphicon glyphicon-repeat flat-button${waitingForResponse ? ' disabled' : ''}`}
                title={"Re-type last element"}
                onClick={e => retype(idx)}
              ></span>
              {isToolUseRequest && (<span
                key={`replay-${key}`}
                className={`admin glyphicon glyphicon-refresh flat-button${waitingForResponse ? ' disabled' : ''}`}
                title={"Replay from here"}
                onClick={e => replayConversation(idx)}
              ></span>)}
            </div>
          );
        }
        if (toolResult) {
          // NOTE: we're ignoring any text (e.g. interrupt) we might have sent with the tool result
          const toolInfo = toolUseInfo[toolResult?.toolUseId];
          const title = status === "error"
                        ? `Tool invocation failed${isAdmin ? ' ' + JSON.stringify(toolResult) : ''}`
                        : toolInfo.name === TOOL_RESOLVE
                          ? toolResult.content[0].json?.options.filter(el => el).map(el => el.id).join(", ")
                          : toolInfo.name === TOOL_SEARCH_AGENT || toolInfo.name === TOOL_SEARCH
                            ? (toolResult.content.map(el => el.text || "").join("\n") || "(no response)")
                            : `<code>${escapeMD(JSON.stringify(toolResult))}</code>`;
          const className = `${role} turn-${key}${errorClass} tool-result`;
          const text = status === 'error' && !isAdmin ? `Server error` : title;
          //console.debug(`Render tool result: ${text}`);
          const toolResultText = stopReason === "user_interrupt" ? "User interrupted" : "Results received for";
          return (
            <Fragment key={key}>
              {isAdmin || showToolResults && (
                <div key={`tool-result-text-${key}`} title="Agent response" className={`${className} tool-result-text turn`}>
                  {renderLLMMarkdown(text)}
                  {stopReason === "max_tokens" ? (<span className="truncated">(truncated response{tokenUsage})</span>) : ""}
                </div>)}
              {isAdmin && (
                <div key={`tool-result-${key}`}
                     title={title}
                     className={`admin ${className}`}>
                  {toolResultText} <em>{toolInfo.name}</em>
                </div>)}
            </Fragment>
          );
        }
        if (role === "system") {
          return (
            <div key={`system-${key}`} className={`${role} turn-${key}${errorClass} turn`}>{text}</div>
          );
        }
        return (
          <div key={`user-${key}`} className={`${role} turn-${key} turn`}>
            {role === "user" ? (<Avatar key={`avatar-${key}`} />) : ""}
            {text.split("\n").map((s, pidx) => (<span key={`user-${key}.${pidx}`}>{s}<br/></span>))}
            {role === "user" ? (
              <>
                <span
                  key={`discard-${key}`}
                  className={`glyphicon glyphicon-trash flat-button${waitingForResponse ? ' disabled' : ''}`}
                  title={"Discard from here"}
                  onClick={e => discardConversation(idx)}
                ></span>
                <span
                  key={`replay-${key}`}
                  className={`glyphicon glyphicon-refresh flat-button${waitingForResponse ? ' disabled' : ''}`}
                  title={"Replay from here"}
                  onClick={e => replayConversation(idx)}
                ></span>
              </>
            ) : ""}
          </div>
        );
      });
      const fileBlocks = content
        .filter(block => block.document || block.image)
        .map(block => block.document || block.image);
      if (fileBlocks.length) {
        //console.log("Found content blocks", fileBlocks);
        const files = fileBlocks.map(block => block.source.file);
        //console.log("Found attachments", files);
        blocks.push((
                      <FileAttachments key="attachments" value={files} readOnly={true} />
                    ));
      }
      return blocks;
    });
  };

  const handleScroll = e => {
    updateScrollState();
  };

  //console.log("Render chat session, attachments", attachments);
  return (
    <div key="session" className={`chat-session${className ? " " + className : ""}`}>
        {showConversation && (
          <div ref={convoRef} key="conversation" className="conversation" onScroll={handleScroll} >
            {showWelcome && (<div key="welcome" className="system">{WELCOME}</div>)}
            {renderConversation(convo)}
            <div key="convo-tail"
                 className="convo-tail"
                 ref={convoTailRef}>
            </div>
          </div>
        )}
      {convo.length > 0 && (
        <div key="controls" className={"controls"}>
          <CopyToClipboardButton
            key="controls-copy"
            className={"glyphicon glyphicon-copy"}
            title={"Copy conversation to clipboard"}
            getText={e => getClipboardContents(e.shiftKey || e.altKey)}
          />
          <LoadingAnimation key="loading-animation" style={{visibility: waitingForResponse ? "visible" : "hidden"}}/>
          <button
            key="controls-clear"
            className={"glyphicon glyphicon-trash"}
            title={"Clear the conversation"}
            onClick={clearMessages}
          />
        </div>
      )}
      <div key="chat-control" className={`chat-box-control`}>
        <Textarea
          key={"input"}
          className={"prompt"}
          ref={inputRef}
          disabled={waitingForResults}
          placeholder={userInputPlaceholder}
          value={waitingForResults ? "" : inputText || ""}
          onChange={inputChanged}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        />
        <button
          key={"send"}
          className={"send"}
          disabled={!inputText.trim() || waitingForResponse || waitingForResults}
          title={inputText ? "Send this message to Plexy" : "Type something to send to Plexy"}
          onClick={handleSendClick}
        >
          <span className={"glyphicon glyphicon-play"}></span>
        </button>
        {useTools && false && (
          <button
            key={"stop"}
            className={"stop"}
            disabled={!convo.length || !waitingForResponse || interrupted || !useTools || waitingForResults}
            title={interrupted ? "Interrupting..." : waitingForResults ? "Interrupt Plexy" : "Plexy is waiting"}
            onClick={() => handleInterrupt()}
          >
            <span className={"glyphicon glyphicon-stop"}></span>
          </button>
        )}
        {onSearchClick && (<button
          key={"chat"}
          className={"chat"}
          title={"Switch to search mode"}
          onClick={onSearchClick}
        >
          <span className={"glyphicon glyphicon-search"}></span>
        </button>)}
      </div>
      {useTools && allowAttachments && (
        <FileAttachments
          uploadURL={"/api/upload"}
          value={attachments}
          onChange={files => setAttachments(files)}
          authData={userStore}
          documentLimit={DOC_LIMIT}
          documentSizeLimit={DOC_SIZE_LIMIT}
          imageLimit={IMAGE_LIMIT}
          imageSizeLimit={IMAGE_SIZE_LIMIT}
          accept={FILE_INPUT_ACCEPT.join(',')}
        />
      )}
      {isAdmin && (
        <div key="templates" className={"admin ai-templates"}>
          <SimpleSelect
            className={"admin template-select"}
            options={Object.keys(aiTemplates)}
            value={selectedTemplate}
            onChange={(value) => setSelectedTemplate(value)}
          />
          <textarea
            className={"template-editor"}
            onKeyDown={e => {
              if (e.key === "Escape") {
                setTemplateText(aiTemplates[selectedTemplate]);
              }
            }}
            onChange={e => setTemplateText(e.target.value)}
            value={templateText}
          />
        </div>
      )}
      {showSettings && searchParams && (
        <ChatSettings
          key={"settings"}
          onChange={setChatSettings}
          categories={categories}
          disabled={convoStarted}
          userStore={userStore}
          appStatusStore={appStatusStore}
        />
      )}
    </div>
  )
};

export default ChatSession;

import React from "react";
import {inject, observer} from "mobx-react";
import logo from "../img/official_logo.png";

const ErrorPage = ({error, stackTrace, userStore}) => {
  return (
    <div className="app-container error-page">
      <div className="logo-wrapper">
        <img src={logo} className="app-logo" alt="Plex Research" />
      </div>
      <div className="error-content">
      <span>
        We seem to have encountered a problem. Please go back to the <a href="/">main page</a> and try again.
      </span>
        <br />
        {userStore.isAdmin && (
          <details>
            <div className="error-header">{error && error.toString()}</div>
            <div className="error-details">{
              stackTrace?.split("\n").map((line, idx) => (<span key={idx}>{line}<br/></span>))
            }</div>
          </details>
        )}
      </div>
      <div>
        <a href={"/"}>Back to the main page</a>
      </div>
    </div>
  );
}

export default inject("userStore")(observer(ErrorPage));

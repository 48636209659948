import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import { checkOfflineState } from "./OfflineDetect";

const FiltersBox = ({ searchSessionStore }) => {
  const {
    params: { directConnectionID: dc, neighborhood: nbr },
    filterByDirectConnection,
    filterByCommunity,
    entities,
  } = searchSessionStore;
  const label = entities[dc]?.label ? `${entities[dc].label}` : `${dc}`;
  const title = entities[dc]?.label ? `${label} (${dc})`: label;

  return (
    <ul className="custom-nav-bar filter-box filter-bar">
      {dc || (nbr || nbr === 0) ? (
        <Fragment>
          <li>Filters</li>
          {dc ? (
            <li className="results-filter results-dc-filter">
              <Button
                size="sm"
                title={`Show only entities directly connected to to ${title}`}
                onClick={checkOfflineState(() => filterByDirectConnection(null))}
              >
                {`Connected to ${label}`}
                <span className="glyphicon glyphicon-remove filter-box-token" />
              </Button>
            </li>
          ) : null}
          {nbr || nbr === 0 ? (
            <li className="results-filter results-nbr-filter">
              <Button
                size="sm"
                title={`Community ${nbr}`}
                onClick={checkOfflineState(() => filterByCommunity(null))}
              >
                {`Community ${nbr}`}
                <span className="glyphicon glyphicon-remove filter-box-token" />
              </Button>
            </li>
          ) : null}
        </Fragment>
      ) : null}
    </ul>
  );
};

FiltersBox.propTypes = {
  directConnectionID: PropTypes.object,
  neighborhood: PropTypes.object,
  handleClearDC: PropTypes.func,
  handleClearNBH1: PropTypes.func
};

export default inject("searchSessionStore")(observer(FiltersBox));

import React, {StrictMode, useEffect} from "react";
import {createRoot} from "react-dom/client";
import {useLocation, useNavigationType, createRoutesFromChildren, matchRoutes} from "react-router";
import App from "./pages/App";
import LandingPage from "./pages/LandingPage";
import Authentication from "./pages/auth/Authentication";
import Registration from "./pages/auth/Registration";
import {PasswordChange, RequestAccessLink} from "./pages/auth/ChangePassword";
import CustomRendererSandbox from "./templates";
import SearchResults from "./pages/SearchResults";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Navigate } from "react-router";
import { Provider } from "mobx-react";
import { rootStore } from "./stores";
import {BETA, DEV, getConfig} from "./lib/configMgr";
import GlobalErrorBoundary from "./components/GlobalErrorBoundary";
import OfflineDetect from "./components/OfflineDetect";
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";
import AdminPage from "./pages/Admin";
import MaintenancePage from "./pages/MaintenancePage";
import ErrorPage from "./pages/ErrorPage";
import GraphPlayground from "./pages/SigmaGraphPlayground";
import Help from "./pages/Help";
export * from "./styles";

const {
  Sentry: sentryOptions,
  env,
  cell,
} = getConfig();

const enableSentry = !DEV && sentryOptions.DSN !== "";
//const enableSentry = sentryOptions.DSN !== "";

if (enableSentry) {
  try {
    Sentry.init({
                  dsn: sentryOptions.DSN,
                  integrations: [
                    Sentry.captureConsoleIntegration({levels: ['warn', 'error', 'assert']}),
                    Sentry.browserTracingIntegration({
                      routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration({
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes,
                        //stripBasename,
                      }),
                    }),
                  ],
                  release: sentryOptions.release,
                  environment: cell === "beta" ? "staging" : env,
                  //debug: cell === "beta" || env === 'dev',
                  // We recommend adjusting this value in production, or using tracesSampler
                  // for finer control
                  tracesSampleRate: 1.0,
                });
    Sentry.setTags(sentryOptions.tags);
  }
  catch(e) {
    console.warn(`Sentry disabled`, e);
  }
}

console.log(`DEV=${DEV}`);
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {index: true, element: <LandingPage />},
        {path: "chat", element: <LandingPage chat={true} />},
        {path: "search", element: <SearchResults />},
      ]
    },
    {path: "graph", element: DEV ? <GraphPlayground /> : <Navigate to={"/"} replace />},
    {path: "help", element: <Help />},
    {path: "admin", element: <AdminPage />},
    {path: "signin", element: <Authentication />},
    {path: "register", element: <Registration />},
    {path: "changepass", element: <PasswordChange />},
    {path: "resetpass", element: <RequestAccessLink />},
    {path: "sandbox", element: (DEV || BETA) ? <CustomRendererSandbox /> : <Navigate to={"/"} replace />},
    {path: "maintenance", element: <MaintenancePage />},
    {path: "*", element: <Navigate to={"/"} replace />},
  ]
);

const strict_mode = false;
const root = createRoot(document.getElementById('root'));
const content = (
  <GlobalErrorBoundary>
    <Provider {...rootStore}>
      <RouterProvider router={router}/>
      <OfflineDetect/>
    </Provider>
  </GlobalErrorBoundary>
);

if (strict_mode) {
  root.render(<StrictMode>{content}</StrictMode>);
}
else {
  root.render(content);
}

import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

import { getConfig } from "./configMgr";
import {getMessageObject, MessageType} from "../pages/auth/FormLayout/MessageFactory";
import * as Sentry from "@sentry/core";

const { tenantID } = getConfig();

class AWSCognitoProvider {
    constructor(region, userPoolID, clientID) {
        const poolData = {
            UserPoolId: userPoolID,
            ClientId: clientID
        };
        this.userPool = new CognitoUserPool(poolData);
    }

    retrieveUserSession = async () => {
        const user = this.userPool.getCurrentUser();
        if (user) {
            Sentry.setUser({username: user.getUsername()});
            return new Promise((resolve, reject) => {
                // getSession implicitly refreshes session
                // https://github.com/aws/amazon-cognito-identity-js/blob/master/src/CognitoUser.js#L1059
                user.getSession((err, session) => {
                    console.debug("Get session returned", session);
                    if (err) {
                        Sentry.setUser(null);
                        reject(err);
                    }
                    else {
                        user.getUserAttributes((err, attrs = []) => {
                            if (err) {
                                reject(err);
                            } else {
                                const attributes = attrs.reduce((result, entry) => ({...result, [entry['Name']]: entry['Value']}), {});
                                resolve({session, user, attributes});
                            }
                        });
                    }
                });
            });
        }
        return {session: null, user: null, attributes: null};
    }

    signIn(username, password, rememberMe, navigate, verificationCode = null, newPassword = null) {
        username = username.toLowerCase();
        const validationData = verificationCode ? {token: verificationCode} : {};
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
            ValidationData: validationData
        });
        const userData = { Username: username, Pool: this.userPool };
        const cognitoUser = new CognitoUser(userData);
        if (tenantID !== "") {
            validationData['tenant_id'] = tenantID;
        }
        return new Promise((resolve, reject) => {
            Sentry.setUser({username});
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: session => {
                    if (!rememberMe) {
                        window.addEventListener("beforeunload", ev => this.signOut(username))
                    }
                    cognitoUser.getUserAttributes((err, attrs = []) => {
                        if (err) {
                            reject(err);
                        } else {
                            const attributes = attrs.reduce((result, entry) => ({...result, [entry['Name']]: entry['Value']}), {});
                            resolve({session, user: cognitoUser, attributes});
                        }
                    });
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    // If we've already got the user's new password, install it as the new one
                    if (newPassword) {
                        delete userAttributes.email_verified;
                        delete userAttributes.email;
                        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                            onSuccess: result => {
                                this.retrieveUserSession()
                                  .then(({session, user}) => resolve({session, user}))
                                  .catch(err => reject(err));
                            },
                            onFailure: err => {
                                console.warn("Unable to change password", err);
                                Sentry.captureMessage(`Password change failed for ${username}: ${err}`, "warning");
                                reject(err);
                            }
                        });
                    }
                    else {
                        // Present the user with the password change form to get a new password
                        navigate("/changepass", {
                            state: {
                                username, oldPassword: password, userAttributes, requiredAttributes,
                                message: getMessageObject(MessageType.PasswordResetRequiredException),
                            },
                        });
                    }
                },
                onFailure: err => {
                    Sentry.captureMessage(`Login failed: ${err}`, "warning");
                    // reset password handler
                    // https://stackoverflow.com/questions/38110615/how-to-allow-my-user-to-reset-their-password-on-cognito-user-pools
                    if (err.name === MessageType.PasswordResetRequiredException) {
                        cognitoUser.forgotPassword({
                            onSuccess: result => {
                                this.retrieveUserSession()
                                  .then(({session, user}) => resolve({session, user}))
                                  .catch(err => reject(err));
                            },
                            inputVerificationCode: () => {
                                const msg = `Your password must be changed. Follow the access link sent to ${username}.`;
                                navigate("/resetpass", {
                                    state: {
                                        username,
                                        accessLinkSent: true,
                                        message: {
                                            type: "error",
                                            message: msg,
                                        }
                                    },
                                });
                            },
                            onFailure: err => {
                                console.warn('Unable to generate new access link', err);
                                Sentry.captureMessage(`Password reset failed for ${username}: ${err}`, "warning");
                                navigate("/resetpass", {
                                    state: {
                                        username,
                                        accessLinkSent: false,
                                        message: {
                                            type: "error",
                                            message: "Your password must be changed",
                                        }
                                    },
                                })
                                //reject(err);
                            },
                        });
                    }
                    else {
                        reject(err);
                    }
                },
            });
        });
    }

    register(username, password) {
        username = username.toLowerCase();
        Sentry.setUser({username});
        const origin = window.location.origin === "http://localhost:3000"
                       ? "https://plexsearch.com" : window.location.origin;
        const attributeList = [
            { Name: 'email', Value: username },
            { Name: 'website', Value: window.location.origin }
        ];

        return new Promise((resolve, reject) => {
            this.userPool.signUp(username, password, attributeList, null,
                                 (err, result) => {
                                     if (err) {
                                         Sentry.captureMessage(`Registration failed: ${err}`, "warning");
                                         reject(err);
                                     }
                                     else {
                                         Sentry.captureMessage(`Registration started`, "log");
                                         resolve(result);
                                     }
                                 });
        });
    }

    signOut(username) {
        const cognitoUser = new CognitoUser({ Username: username.toLowerCase(), Pool: this.userPool });
        cognitoUser.signOut();
        Sentry.setUser(null);
    }

    resendConfirmation(username) {
        username = username.toLowerCase();
        Sentry.setUser({username})
        const cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
        return new Promise((resolve, reject) => {
            cognitoUser.resendConfirmationCode((err, result) => {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(result);
                }
            });
        });
    }

    sendVerificationCode(username) {
        username = username.toLowerCase();
        Sentry.setUser({username})
        const cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({onSuccess: resolve, onFailure: reject});
        });
    }

    confirmNewPassword(username, verificationCode, newPassword) {
        username = username.toLowerCase();
        Sentry.setUser({username})
        const cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: resolve,
                onFailure: reject
            });
        });
    }

    changePassword(username, oldPassword, newPassword, navigate) {
        const cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser) {
            return new Promise((resolve, reject) => {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
                        // "result" is just "SUCCESS", which is useless, so return the session instead
                        if (err) {
                            reject(err);
                            return;
                        }
                        resolve({session, user: cognitoUser});
                    });
                });
            });
        }
        return this.signIn(username, oldPassword, true, navigate, null, newPassword);
    }
}

export default AWSCognitoProvider;

import React from "react";
import {checkOfflineState} from "../../OfflineDetect";

const SearchResultHeader = ({
  rank,
  total,
  globalRank = null,
  ordinal = 0,
  title = "<missing title>",
  showMatch = false,
  onClick = () => {}
}) => {
  const grank = globalRank ? ` (${globalRank})` : '';
  const hover = `Rank ${ordinal} of ${total}${grank}`
  return (
    <div className="sr-header">
      <span key="rank" title={hover} className={"sr-header"}>
        &nbsp;{rank}&nbsp;
      </span>
      <span key="title" className="sr-title">
        <span className={showMatch ? "sr-link-disabled" : ""}
              title={showMatch ? "Query term" : "Click to see supporting data"}
              onClick={showMatch ? () => {
              } : checkOfflineState(onClick)}>
          {title}
        </span>&nbsp;
        {showMatch && (
          <span title="Item is a query term" className="glyphicon glyphicon-ok"/>
        )}
      </span>
    </div>
  );
};

export const MDSearchResultHeader = ({
                                       rank,
                                       ordinal,
                                       total,
                                       entity = {},
                                       showMatch = false,
                                       markText = null,
                                       onShowEvidence = () => {}
                                     }) => {
  const globalRank = entity.global_rank;
  const title = entity.label || entity.title;
  return (
    <SearchResultHeader
      rank={rank}
      total={total}
      globalRank={globalRank}
      ordinal={ordinal}
      title={markText ? markText(title) : title}
      showMatch={showMatch}
      onClick={onShowEvidence}
    />
  );
}


export default SearchResultHeader;

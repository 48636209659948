import React, {useEffect, useRef, useState} from 'react'
import cytoscape from "cytoscape";


const PLEX_YELLOW = "#f7be33";
const PLEX_ORANGE = "#e4762f";
const PLEX_RED = "#e54028";
const PLEX_BLACK = "#000";

const DOT_SIZE = 16
const DOT_MIN_SIZE = 8

const EDGE_COLOR = "#000";
const EDGE_WIDTH = 2
const EASE = "ease-in-out-sine";

const LoadingNetworkAnimation = ({}) => {
  const NODES = ["black", "red", "orange", "yellow"];
  const containerRef = useRef();
  const [cy, setCy] = useState(null);

  useEffect(() => {
    if (containerRef.current) {
      const elements = [
        ...NODES.map(name => ({
          data: {id: name, label: ""},
          classes: [`plex-${name}`],
        })),
        ...NODES.map((name, idx) => ({
          data: {source: name, target: idx + 1 < NODES.length ? NODES[idx + 1] : NODES[0]},
          classes: ['loading-animation-edge'],
        }))
      ];
      const cyto = cytoscape({
                               container: containerRef.current,
                               autoungrabify: true,
                               autounselectify: true,
                               maxZoom: 1,
                               minZoom: 1,
                               motionBlur: true,
                               boxSelectionEnabled: false,
                               zoomingEnabled: false,
                               userZoomingEnabled: false,
                               panningEnabled: false,
                               userPanningEnabled: false,
                               elements,
                               layout: {name: "circle"},
                               style: [
                                 {
                                   selector: "core",
                                   style: {"active-bg-size": 0},
                                 },
                                 {
                                   selector: "node",
                                   style: {"width": DOT_SIZE, "height": DOT_SIZE, "overlay-opacity": 0}
                                 },
                                 {
                                   selector: "edge",
                                   style: {"width": EDGE_WIDTH, "color": EDGE_COLOR, "overlay-opacity": 0}
                                 },
                                 {
                                   selector: "node.plex-yellow",
                                   style: {"background-color": PLEX_YELLOW}
                                 },
                                 {
                                   selector: "node.plex-orange",
                                   style: {"background-color": PLEX_ORANGE}
                                 },
                                 {
                                   selector: "node.plex-red",
                                   style: {"background-color": PLEX_RED}
                                 },
                                 {
                                   selector: "node.plex-black",
                                   style: {"background-color": PLEX_BLACK}
                                 },
                               ]
                             });
      setCy(cyto);
      return () => cyto.destroy();
    }
  }, [containerRef.current]);

  const animateNode = (n, w, h, grow = true, delay = 1000) => {
    const randomPosition = base => (base / 8 + Math.random() * base / 2);
    cy.nodes(`.plex-${n}`)
      .delay(Math.random() * delay)
      .animate({
                 renderedPosition: {
                   x: randomPosition(w),
                   y: randomPosition(h),
                 },
                 style: grow || Math.random() < 0.5
                        ? {width: DOT_SIZE, height: DOT_SIZE}
                        : {width: DOT_MIN_SIZE, height: DOT_MIN_SIZE},
                 easing: EASE,
                 duration: 1000,
                 complete: () => animateNode(n, w, h, !grow, 0),
               });
  };

  useEffect(() => {
    const el = containerRef.current;
    if (cy && el) {
      NODES.forEach(n => animateNode(n, el.clientWidth, el.clientHeight, Math.random() > 0.85));
    }
  }, [cy, containerRef.current]);
  return (
    <div className="graph-container" ref={containerRef} />
  )
};


const LoadingAnimation = ({className, network = false, ...props}) => (
  <div
    key="loading-animation"
    className={`loading-animation${network ? ' graph-loading-animation' : ''}${className ? ' ' + className : ''}`}
    {...props}
  >
    {network
     ? (<LoadingNetworkAnimation />)
     : (
       <div key="slide-container" className="slide-container">
         <div key="dot-1" className="sliding-dot"></div>
         <div key="dot-2" className="sliding-dot"></div>
         <div key="dot-3" className="sliding-dot"></div>
         <div key="dot-4" className="sliding-dot"></div>
       </div>)}
  </div>
)

export default LoadingAnimation;

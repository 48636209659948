import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import SignIn from "./SignIn.jsx";
import FormLayout from "../FormLayout";
import { validateUsername } from "../validation";
import { inject, observer } from "mobx-react";
import { getMessageObject, MessageType } from "../FormLayout/MessageFactory";

const Authentication = ({userStore, appStatusStore}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const {isAuthorized} = userStore;
  const {serverOffline: offline} = appStatusStore;

  const {
    username: _username = params.get('email') || "",
    password: _password = "",
    message: _message = null,
    from = null,
  } = location.state || {};

  const confirmationStatus = params.get('confirmationStatus');

  const [username, setUsername] = useState(_username);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [password, setPassword] = useState(_password);
  const [messageObject, setMessageObject] = useState(confirmationStatus === "0"
          ? getMessageObject(MessageType.UserConfirmed, username)
          : _message);
  const [rememberMe, setRememberMe] = useState(true);
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);

  useEffect(() => {
    if (_message != null) {
      setMessageObject(_message);
    }
  }, [_message])

  useEffect(() => {
    if (offline) {
      navigate("/maintenance", {replace:true, state: location.state});
    }
    else if (isAuthorized) {
      if (from != null) {
        navigate(from, {replace: true});
      }
      else {
        navigate("/", {replace: true});
      }
    }
  }, [isAuthorized, offline]);

  const handleSignin = () => {
    setIsRequestProcessing(true);
    const {from = "/"} = location.state || {from: {"pathname": "/"}};
    userStore
      .signIn(username, password, rememberMe, navigate)
      .then(() => navigate(from, {replace:true}))
      .catch(error => {
        setIsRequestProcessing(false);
        if (error.code === MessageType.UserLambdaValidationException) {
          handleLambdaValidatonException(error);
        }
        else if (error.code === MessageType.LimitExceededException) {
          console.warn("AWS limits exceeded");
        }
        else {
          if (error.code) {
            console.warn("Sign-in error", error.code, error.message);
          }
          else {
            console.error("Sign-in error", error);
          }
          setMessageObject(getMessageObject(error, username));
        }
      });
  };

  useEffect(() => {
    if (_username !== "" && _password !== "") {
      handleSignin();
    }
  }, [_username, _password]);

  const handleLambdaValidatonException = error => {
    const msg = error && error.message ? error.message : "";
    if (msg.includes("password-expired")) {
      navigate("/changepass", {
        state: {
          username: username,
          oldPassword: password,
          verificationCode: msg.replace(/.*password-expired:([0-9a-f]+).*/, "$1"),
          message: getMessageObject(MessageType.PasswordExpired, username)
        }
      });
    }
    else if (msg.includes("unauthorized")) {
      setMessageObject(getMessageObject(MessageType.NotAuthorizedException, username));
    }
    else {
      setMessageObject(getMessageObject(MessageType.LoginError, username));
    }
  };

  const redirectTo = (path, state = {}) => {
    navigate(path, {state: { ...state, username, password, rememberMe }});
  };

  const handleInputChange = (fn, value) => {
    if (fn === setUsername) {
      value = value.trim();
    }
    fn(value);
    setMessageObject(null);
  };

  const usernameValid = validateUsername(username) === false;
  useEffect(() => {
    if (username && !usernameValid && usernameTouched) {
      setMessageObject(getMessageObject(MessageType.InvalidUsername, username));
    }
  }, [username, usernameValid, usernameTouched]);

  const enableSubmit =
    username && password && usernameValid && !isRequestProcessing && !isAuthorized;

  return (
    <FormLayout message={messageObject}>
      <SignIn
        username={username}
        enableSubmit={enableSubmit}
        isRequestProcessing={isRequestProcessing}
        onUsernameChange={e => handleInputChange(setUsername, e.target.value)}
        onUsernameBlur={e => setUsernameTouched(true)}
        password={password}
        onPasswordChange={e => handleInputChange(setPassword, e.target.value)}
        onSignIn={handleSignin}
        rememberMe={rememberMe}
        onRememberMeChange={e => handleInputChange(setRememberMe, !rememberMe)}
        onRegisterClick={_ => redirectTo("/register")}
        onForgotPass={_ => redirectTo("/resetpass", {accessLinkSent: false})}
      />
    </FormLayout>
  );
}

export default inject("appStatusStore", "userStore")(observer(Authentication));

import React from "react";
import {copyToClipboard} from "../lib/utils";

const CopyToClipboardButton = ({getText = () => "", className = "", disabled = false, title = "Copy to clipboard"}) => (
  <button
    disabled={disabled}
    title={title}
    className={`copy-to-clipboard glyphicon glyphicon-copy${className ? ' ' + className : ''}${disabled ? ' disabled' : ''}`}
    onClick={e => copyToClipboard(getText(e))} />
);

export default CopyToClipboardButton;

import React from "react";
import {renderMarkdownWithInterpolation} from "../../markdown/markdown-factory";
import {inject, observer} from "mobx-react";
import {TEMPLATE_CONSTANTS} from "../../markdown";
import {QD_Default} from "../../markdown/query-summary/templates";
import MoleculeImage from "../MoleculeImage";
import {
  MDCollapsibleList,
  MDCollapsibleText
} from "../results-grid/grid-row/MDCollapsibleContent";
import LabeledEntity from "../LabeledEntity";

import {markMatchingText} from "../../markdown/utils";

const QueryDetails = ({
  entity,
  markText = null,
  templates,
  expandAliases,
  onAliasesToggle,
  searchSessionStore: { datasets }

}) => {
  return (
    <div className="query-details" >
      {renderMarkdownWithInterpolation({
                                         templateType: TEMPLATE_CONSTANTS.QUERY_DETAILS,
                                         templates,
                                         templateOverrides: {
                                           Entity: {
                                             component: LabeledEntity,
                                             props: {scale: "lg", dataset: datasets[entity.dataset]}
                                           },
                                           SMILES: {
                                             component: MoleculeImage,
                                             props: {encoding: entity.smiles || entity.inchi}
                                           },
                                           Text: {
                                             component: MDCollapsibleText
                                           },
                                           List: {
                                             component: MDCollapsibleList,
                                             props: {isOpen: expandAliases, onToggle: onAliasesToggle},
                                           },
                                         },
                                         entity,
                                         markText,
                                         fallback: QD_Default,
                                       })}
    </div>);
}

export default inject("searchSessionStore")(observer(QueryDetails));

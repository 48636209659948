import {applyFilter} from "./interpolation-filter";
import {getExternalID} from "../lib/utils";
import {deriveTemplate, TEMPLATE_CONSTANTS} from "./index";
import {unescapeMD} from "./utils";

// Needs to handle entity properties and potential component properties injected into the entity
// { name1 name2 ... | filter1 | filter2 ... }
export const RE_PROPERTY_EXPR = /^_?[a-z][-\w]+(\s+[a-z][-\w]+)*(\s*\|\s*\S+.*)?$/;

export const IPROP_PREFIX = "_interpolated_";

const RE_INTERPOLATION_ENTIRE = /^\{\s*[a-z][-a-z_]+[^}]*}$/;
const RE_INTERPOLATION = /\{\s*((?!_interpolated_)[_a-z][-a-z_]+[^}]*)}/g;
const RE_JSX_COMPONENT = /<([A-Z][A-Za-z]+)([^\/>]+)(\/?>)/g;
const RE_JSX_ATTRIBUTE = /(?<=\s)([a-z][a-zA-Z]+)=\{([^}]+)}/g;

const hasValue = (x) => !!x || x === false || x === 0;

const composeInterpolation = (keys, filters, props = {}, interpolatedProps = {}, uniqueValues,
                              overrides = {}, markText = null, quote = x => x) => {
  // Compose a single interpolation between curly braces
  const NOVALUE = "";
  const [propName, propValue] = keys.reduce((result, name) => {
    if (hasValue(result[1])) {
      return result;
    }
    return [name, props[name]];
  }, [null, null]);
  if (hasValue(propValue)) {
    // Filters may result in markup, so quote before filtering
    const filtered = filters.reduce((result, [filter, filterArg]) => {
      if (result === NOVALUE) {
        return NOVALUE;
      }
      try {
        return applyFilter(result, filter, filterArg, uniqueValues, overrides,
                           interpolatedProps, propName, markText, quote);
      } catch (e) {
        console.error(`Could not apply filter '${filter}' to '${result}'`, e);
        return result;
      }
    }, propValue);
    if (hasValue(filtered)) {
      if (typeof(propValue) === 'string' && uniqueValues) {
        uniqueValues.add(propValue.toLowerCase());
        if (props.id === `smiles:${propValue}` || props.id === `query:${propValue}`) {
          uniqueValues.add(props.id.toLowerCase());
        }
      }
      return filtered;
    }
  }
  return NOVALUE;
};

export const substituteAttributeValue = (attributeName, value, componentProps, markText = null) => {
  // Replace the attribute with an interpolated attribute
  const propName = `${IPROP_PREFIX}${attributeName}_${Object.keys(componentProps).length}`;
  componentProps[propName] = markText && typeof (value) === "string"
                             ? markText(value)
                             : value;
  return propName;
}

export const interpolate = (template,
                            entity,
                            componentProps = {},
                            markText = null,
                            uniqueValues = new Set(),
                            overrides = {},
                            quote = x => x) => {
  if (typeof (template) !== 'string') {
    return template;
  }
  if (RE_INTERPOLATION_ENTIRE.test(template || "")) {
    const value = "" + evaluateInterpolationExpression(template.substring(1, template.length - 1), entity,
                                                              componentProps, uniqueValues, overrides,
                                                              markText, quote);
    return markText ? markText(value) : value;
  }

  // Account for multiple interpolations within a property, e.g. title='{foo} {bar}'
  // markdown-to-jsx interpolates '{EXP}' expressions as either string or boolean
  // Handle HTML/JSX element attributes "foo={bar}"; the expression is evaluated and the property value injected
  // into componentProps.  A property placeholder is inserted for later resolution to avoid property name conflicts
  // and allow properties to have JSX values (e.g. markText).
  // JSX components must start with a capital letter.
  template = template.replace(RE_JSX_COMPONENT, (match, componentName, attributes, closure) => {
    const overrideProps = overrides[componentName]?.props || {}
    const attrs = attributes.replace(RE_JSX_ATTRIBUTE, (match, attributeName, attributeValue) => {
      const value = evaluateInterpolationExpression(attributeValue, entity, {...componentProps, ...overrideProps},
                                                    uniqueValues, overrides);
      const propName= substituteAttributeValue(attributeName, value, componentProps, markText);
      return `${attributeName}={${propName}}`;
    });
    return `<${componentName}${attrs}${closure}`;
  });
  // Handle "{bar}" in freeform text
  let idx = 0;
  return template.replace(RE_INTERPOLATION, (match, expr) => {
    const interpolated = evaluateInterpolationExpression(expr, entity, componentProps,
                                                         uniqueValues, overrides,
                                                         markText, quote);
    const value = interpolated === false ? match : interpolated;
    // Assume any markdown is already properly quoted
    if (typeof (value) !== "string" || (value.startsWith("<") && value.endsWith(">"))) {
      return "" + value;
    }
    const trimmed = value
      .replace(/(\s*\n\s*)+/g, "\n")
      .replace(/\n/g, "<br/>");
    const marked = markText && markText(trimmed);
    if (marked) {
      const propName = `${IPROP_PREFIX}inline_${Object.keys(componentProps).length}`;
      componentProps[propName] = marked;
      // Child rendering will replace the value of the property attribute
      const key = `${propName}-${++idx}`;
      return `<InterpolatedProperty property={${propName}} key="${key}"/>`;
    }
    return quote(trimmed);
  });
};

const evaluateInterpolationExpression = (expr, entity, interpolatedProps = {}, uniqueValues,
                                         overrides = {}, markText = null, quote = x => x) => {
  if (expr === "__dump__") {
    return JSON.stringify(entity);
  }
  if (typeof (expr) === 'string' && RE_PROPERTY_EXPR.test(expr)) {
    const parts = expr.split('|').map(x => x.trim());
    const keys = parts[0].split(/\s+/).map(x => unescapeMD(x.trim()));
    const filters = parts.slice(1).map(x => x.split('=')
      .map(x => x.trim().replace(/^'(.*)'$/, '$1').replace(/^"(.*)"$/, '$1')));
    return composeInterpolation(keys, filters, {...interpolatedProps, ...entity}, interpolatedProps, uniqueValues,
                                overrides, markText, quote);
  }
  return false;
};

export const interpolateProps = (templateProps, entity, interpolatedProps = {}, uniqueValues = new Set(), markText = null, templateOverrides = {}) => {
  // Returns a dict with _only_ those properties which were interpolated
  // TODO: sort by dependency
  const newProps = {};
  Object.keys(templateProps).forEach(k => {
    const value = templateProps[k];
    if (value && typeof(value) === 'string') {
      const interpolated = interpolate(value, entity, interpolatedProps, markText, uniqueValues, templateOverrides);
      if (interpolated !== value) {
        newProps[k] = interpolated;
      }
    }
  });
  return newProps;
};

export const resolvePropertyValue = (value, props = {}) => {
  // Interpolate the given property value, recursively
  if (typeof(value) === "string") {
    if (value === "__dump__") {
      return JSON.stringify(props);
    }
    const interpolated = interpolate(value, props, {}, null, new Set(), {}, x => x);
    // Recurse if the interpolation resulted in a single property name
    if (props[interpolated]) {
      return resolvePropertyValue(props[interpolated], props);
    }
    // Resolve again on any change, to handle recursive property values, e.g. "My title is {title}"
    if (RE_INTERPOLATION.test(interpolated)) {
      //return resolvePropertyValue(interpolated, props);
    }
    return interpolated;
  }
  return value;
};
export const interpolateEdgeDetails = (edge, dataset, fromCat, toCat) => {
  const details = {description: "", ...edge};
  details.id = getExternalID(details.dataset_id);
  const ds_edge_default_props = dataset.edge_properties || {};
  const ds_edge_props = ds_edge_default_props[`${fromCat}-${toCat}`] || ds_edge_default_props[`${toCat}-${fromCat}`] || {};
  const edge_props = {...ds_edge_default_props, ...ds_edge_props};
  details.title = resolvePropertyValue(details.title || edge_props.title,
                                  {...edge_props, ...details}) || "";
  details.description = resolvePropertyValue(details.description || edge_props.description,
                                        {...edge_props, ...details}) || "";
  if (!details.url) {
    details.url = (edge_props.url || (dataset && (dataset.edge_url || dataset.url))) || null;
  }
  if (details.url) {
    details.url = resolvePropertyValue(details.url, {positionalArgs: [], ...edge_props, ...details}, true);
  }
  return details;
};

export const interpolateEntityFields = (
  templates,
  entity,
  dataset = {},
) => {
  const dsCats = dataset.categories;
  const dsCat = (dsCats && dsCats[entity.category]) || {};
  const dsBaseCat = (dsCats && dsCats[entity.baseCategory]) || {};
  const dsCustomProps = {
    ...((dsBaseCat.templates && dsBaseCat.templates[TEMPLATE_CONSTANTS.PROPERTIES]) || {}),
    ...((dsCat.templates && dsCat.templates[TEMPLATE_CONSTANTS.PROPERTIES]) || {}),
  };
  // Evaluate custom dataset properties, then custom category properties
  // property "label" may depend on the title
  const templateProperties = deriveTemplate(templates, TEMPLATE_CONSTANTS.PROPERTIES, entity.category,
                                            entity.baseCategory, dsCustomProps, {});
  const priorityPropNames = [
    ...Object.keys(dsCustomProps).filter(x => x.startsWith("_")),
    ...Object.keys(templateProperties).filter(x => x.startsWith("_")),
    ...Object.keys(dsCustomProps).filter(x => !x.startsWith("_")),
    'title', 'description', 'label'
  ];
  // Never replace these names
  const immutablePropertyNames = new Set(["id", "category", "dataset", "inchi", "inchikey"]);
  // Populate template properties
  // Template definitions should override existing properties (required when indexed data is incorrect or obsolete)
  const merged = {...entity, ...templateProperties};
  priorityPropNames.forEach(name => merged[name] = resolvePropertyValue(merged[name], merged));
  Object.keys(merged)
    .filter(p => !new Set(priorityPropNames).has(p))
    .filter(p => !immutablePropertyNames.has(p))
    .forEach(name => merged[name] = resolvePropertyValue(merged[name], merged));
  Object.entries(merged).forEach(([k, v]) => {
    if (typeof(v) === "string") {
      merged[k] = v.trim();
    }
  });
  return merged;
};
export const InterpolatedProperty = ({property = null}) => property;

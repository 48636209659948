import {ErrorBoundary, useErrorBoundary} from "react-error-boundary";
import {useEffect} from "react";



export const SafeRenderer = ({errorMessage = null, className=null, onError=null, children = null}) => {
  const FallbackRenderer = ({error, resetErrorBoundary}) => {
    return (
      <div
        title={error}
        className={`alert alert-error${className ? ' ' + className : ''}`}
        onClick={() => {console.log("Re-render");resetErrorBoundary()}}
      >
        {errorMessage || "Rendering error"}
      </div>
    );
  };
  const handleError = (error) => {
    console.error(`Render error ${error}`);
  };
  return (
    <ErrorBoundary className="safe-renderer" fallback={FallbackRenderer} onError={onError || handleError}>
      {children}
    </ErrorBoundary>
  );
};



import React, { Fragment, useState } from "react";
import { SplitButton, Dropdown } from "react-bootstrap";

const createMenuItemListWithHeader = (
  list = [],
  hdr = "",
  startIndex = 0,
  onSelect = f => f
) =>
  list.length > 0 ? (
    <Fragment>
      {hdr.length > 0 ? <Dropdown.Header>{hdr}</Dropdown.Header> : null}
      {list.map((item, idx) => (
        <Dropdown.Item
          key={startIndex + idx}
          eventKey={startIndex + idx}
          onClick={() => onSelect(item)}
        >
          {item.name}
        </Dropdown.Item>
      ))}
    </Fragment>
  ) : null;

const ExampleSelector = ({
  exampleList,
  onSelectionChange,
  additionalList = []
}) => {
  const [selectedItem, setSelectedItem] = useState(
    exampleList.length > 0 ? exampleList[0] : null
  );

  const handleSelect = example => {
    console.log(`Selected ${example}`);
    onSelectionChange(example);
    setSelectedItem(example);
  };

  return (
    <SplitButton
      title={selectedItem ? selectedItem.name : "Examples"}
      id="example-button"
      className="dropup"
    >
      {createMenuItemListWithHeader(exampleList, "Templates", 0, handleSelect)}
      {<Dropdown.Divider />}
      {createMenuItemListWithHeader(additionalList, "Non-markdown extensions", exampleList.length, handleSelect)}
    </SplitButton>
  );
};

export default ExampleSelector;

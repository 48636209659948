import {getConfig} from "../lib/configMgr";
import {RequestBuilder, toJSON} from "./request";
import {SearchCanceled} from "./errors";
import {constructSearchAPIRequest} from "../lib/SearchContext";
import {wait} from "../lib/utils";

const { ExternalRESTAPI } = getConfig();

export const fetchConnected = (searchParams, datasetsFilter, category, auth) => {
    return fetchSearch({...searchParams, datasetsFilter, category}, auth, {searchType: "connected"});
}

export const fetchSearch = (searchParams, auth, params = {}) => {
  const {
    searchType = "search",
    isCanceled = () => false,
    onProgress = () => {},
    // If false, return immediately with a searchID; otherwise return once search results are ready
    waitForCompletion = true,
  } = params;
  // If `wait` is true, only returns when initial search results become available
  // Otherwise returns as soon as the searchID is obtained
  const req = constructSearchAPIRequest(ExternalRESTAPI.Search, searchParams);
  return req
    .withJSONContentType()
    .withAuthorization(auth)
    .fetch()
    .then(toJSON)
    .then((result) => {
      if (isCanceled()) {
        throw new SearchCanceled("Search canceled");
      }
      const {search_id: searchID = null, status = null, page_token: pageToken = null, results = null} = result;
      if (!waitForCompletion) {
        return {...(searchID ? {searchID} : {}), ...result};
      }
      if (results || (status && (status === 200 || status >= 400))) {
        return {...(searchType === "search" ? {pageToken} : {}), ...result};
      }
      if (result.stream) {
        // FIXME untested streaming search result status
        return new Promise((resolve, reject) => {
          const eventSource = new EventSource(`${ExternalRESTAPI.Stream}?channel=${result.stream}`);
          eventSource.onmessage = event => {
            const data = JSON.parse(event.data);
            //console.log("Search update", data);
            if (data.status && (data.status === 200 || data.status >= 400)) {
              eventSource.close();
              // Actual results need some post-processing, so re-request
              return req.fetch()
                .then(toJSON)
                .then(result => resolve(result))
                .catch(error => reject(error));
            }
            else {
              onProgress(data.progress, data.total, data.info || "");
            }
          };
          eventSource.onerror = e => {
            eventSource.close();
            console.error("Search failed", e);
            reject(e);
          }
        });
      }
      onProgress(result.progress, result.total, result.info || "");
      // Make sure we use the most recent auth available...
      return wait(5000).then(() => fetchSearch(searchParams, req.auth, {searchType, isCanceled, onProgress}));
    })
};

export const exportSearchResults = (params, auth, format = "svg", baseURL = ExternalRESTAPI.Search) => {
  const exportParams = {"exportFormat": format}
  const req = constructSearchAPIRequest(
    baseURL,
    {
      ...params,
      ...exportParams,
    },
    false
  );

  return req
    .withJSONContentType()
    .withAuthorization(auth)
    .fetch();
};

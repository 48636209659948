import {deburr, escapeRegExp} from "lodash";
import React from "react";
import {NBSP} from "../lib/utils";

export const MARKDOWN_CHARS = "[][`#*_]";
export const RE_MD_CHARS_ESCAPED = new RegExp(`\\\\(${MARKDOWN_CHARS})`, 'g');
export const RE_MD_CHARS = new RegExp(`(?<!\\\\)(${MARKDOWN_CHARS})`, 'g');

// Misc utilities with no better home ATM
const RE_UNICODE_PUNCTUATION = /[\u2000-\u206F\u2E00-\u2E7F\u00B4']/g;
// WARNING: must NOT change the length of the text
export const normalize = text => (
  //text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  deburr(text)
);

export const hasMark = (element) => {
  return element?.querySelector('mark');
};

export const markMatchingText = (withinText, filter, params = {}) => {
  const {asString = false, attrs = {}} = params;
  // Check the full filter, as well as individual words within it
  // Also check word blocks that exclude the final word
  if (!filter || !withinText || typeof (withinText) !== "string") {
    return withinText;
  }
  const options = [normalize(filter)];
  const words = options[0].trim().split(/\s/).filter(el => el && el.length > 2);
  if (words.length > 1) {
    options.push(words.slice(0, -1).join(" "));
  }
  options.push(...words);
  const searchPhrases = Array.from(new Set(options))
    .sort((a, b) => b.length - a.length);

  const protectedLinks = {};
  let idx = 0;
  const sansLinks = withinText
    .replace(/(^\[[^\]]+]:.*$|((?<=\[[^\]]+])(\[[^\]]+]|\([^)]+\))))/mg, expr => {
      const key = `____${++idx}____`;
      protectedLinks[key] = expr;
      return key;
    });
  //console.log("With links protected", escapedText);
  const markPhrase = (phrase, inText, keyBase = "key") => {
    // Match any non-word characters in the input phrase, and allow for extra dash or space preceding digits (i.e.
    // allow "MEK2" to match "MEK-2" or "MEK 2")
    const re_phrase = softMatchRegExp(phrase);
    const output = [];
    const normalized = normalize(inText);
    let offset = 0;
    let match;
    while ((match = re_phrase.exec(normalized)) !== null) {
      if (match.index > offset) {
        const prefix = inText.substring(offset, match.index);
        output.push(prefix);
      }
      const matchedText = inText.substring(match.index, match.index + match[0].length);
      output.push(mark(matchedText, {asString, attrs: {key: `${keyBase}-${++idx}`}}));
      offset = re_phrase.lastIndex;
    }
    if (offset < inText.length) {
      output.push(inText.substring(offset));
    }
    return output;
  }
  const marked = [sansLinks];
  searchPhrases.forEach((phrase, idx) => {
    const updated = [];
    marked.forEach(value => {
      if (typeof (value) !== "string" || value.startsWith("<mark")) {
        // Already marked, leave it alone
        updated.push(value);
      } else if (value) {
        updated.push(...markPhrase(phrase, value, `${phrase.replace(/\W+/g,"-")}-${idx}`));
      }
    });
    marked.splice(0, updated.length, ...updated);
  });
  const withRestoredLinks = marked.map(el => typeof (el) === "string"
                                             ? el.replace(/____\d+____/mg, expr => protectedLinks[expr])
                                             : el);
  //console.log("Output elements", withRestoredLinks);
  return asString ? withRestoredLinks.join(
    "") : withRestoredLinks.length === 1 ? withRestoredLinks[0] : withRestoredLinks;
}

export const mark = (text, {asString = false, attrs = {}}) => {
  if (asString) {
    const sattrs = Object.entries(attrs).reduce((result, [key, value]) => (
      `${result} ${key}="${value}"`
    ), "");
    return `<mark${sattrs}>${text}</mark>`;
  }
  return (<mark {...attrs}>{text}</mark>);
};

// Cf python highlight matching
// Ensure "ICG001" will match "ICG-001" or "ICG:001" or "ICG 001" and vice versa
export const softMatchRegExp = (phrase, flags = 'giu') => {
  const escaped = escapeRegExp(phrase.replace(/\s+/g, "__SPACE__").replace(/[\W_]/gu, "__DOT__"));
  const simplified = escaped.replace("__SPACE__", " ")
    .replace(/__DOT__(__DOT__)*/gu, "((?![\n*])[\\W_])?");
  const relaxed = simplified.replace(/(?<=[^\d\s\W])(\d)/gu, "[-:\\s]?$1");
  return new RegExp(relaxed, "gui");
};

// Patch up markdown to avoid certain markdown compiler bugs/behavior
export const prepareMarkdown = s => {
  // ESCAPE_THIS {NOT_THIS}
  // [ESCAPE_THIS][NOT_THIS]
  // [ESCAPE_THIS](NOT_THIS)

  // Escape underscores where we can be pretty sure they're not markdown
  return s.replace(/(?<=[A-Za-z0-9])(?!interpolated)_(?!inline)(?=[A-Za-z0-9])/g, "\\_")
    // Preserve space after the mark and preceding a previously-existing span
    .replace(/^(<mark.*?<\/mark>) +(?=<span[^>]*>)/gm, `$1${NBSP}`)
    // Markdown will convert "^<mark>.*</mark>(.*)" into "<span><mark>.*</mark><p>$1</p></span>", which introduces
    // a line break after the marked text.  Wrap the trailing raw text in a span to avoid the line break.
    // This is only an issue when <mark> is at the beginning of line
    .replace(/^(<mark[^>]*>[^<]+<\/mark>(?!=<span))(.+)$/gm, "$1<span class='prepared'>$2</span>")
    // Preserve any space at the beginning of an inserted span directly after marked text (only if at BOL)
    .replace(/^(<mark.*?<\/mark><span[^>]*>)? (?!= $)/gm, `$1${NBSP}`)
    // Preserve double-newline after </span>, but only when line starts with <mark>
    .replace(/^(<mark.*?<\/span>)( {2}|\n)\n/gm, "$1<p/>")
    // Escape underscores around marked text
    .replace(/((?<=<\/mark>)_|_(?=<mark))/g, "\\_")
    // Escape square brackets that are probably not links (e.g. IUPAC names)
    .replace(/\[[]]*]\(([^)]+)\)/, (match, url) => url.startsWith("http") ? match : match.replace("([][])", "\\$1"))
    // No escape within interpolation braces
    .replace(/(?<={)([^}]+?\\_[^}]+)(?=})/gs, m => m.replace("\\_", "_"))
    // No escape within hyperlink link definition
    .replace(/(?<=[]]\()([^)]+?\\_[^)]+)(?=\))/gs, m => m.replace("\\_", "_"))
    // No escape within reference link definition
    .replace(/(?<=[]]\[)([^\]]+?\\_[^\]]+)(?=])/gs, m => m.replace("\\_", "_"))
    // No escape between <code> tags
    .replace(/(?<=`)([^`]+?\\_[^`]+)(?=`)/gs, m => m.replace("\\_", "_"))
    // No escape in attribute values
    .replace(/(?<==")([^"]+?\\_[^"]+)(?=")/gs, m => m.replace("\\_", "_"))
    // No escape within angle brackets (assume tag name)
    .replace(/(<[^>]+)\\(_[^>]+>)/gs, m => m.replace("\\_", "_"));
};

export const escapeMD = (obj) => {
  if (obj.map) {
    return obj.map(x => escapeMD(x));
  }
  return obj.toString()
    .replace(RE_MD_CHARS, "\\$1")
    .replace(/(<[^>]+)\\(_[^>]+>)/gs, "$1_$2");
};

export const unescapeMD = (obj) => {
  return obj.toString()
    .replace(RE_MD_CHARS_ESCAPED, "$1");
};


export const getObjectProjection = (obj = {}, fields = []) =>
  fields.length === 0
    ? obj
    : fields.reduce(
        (output, fieldName) =>
          Object.assign(output, { [fieldName]: obj[fieldName] }),
        {}
      );

export const createSample = (name, template, data) => ({
  name: name,
  template: template,
  dataObject: data
});


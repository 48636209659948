import {exportSearchResults, fetchSearch} from "./search";
import {getConfig} from "../lib/configMgr";
import {RequestBuilder} from "./request";
const { ExternalRESTAPI } = getConfig();

export const fetchEChains = (searchParams, targetID, datasetsFilter, auth) => {
    const params = {...searchParams, targetID};
    if (datasetsFilter?.length) {
        params.datasetsFilter = datasetsFilter;
    }
    if (searchParams.exportFormat) {
        return exportSearchResults(params, auth, searchParams.exportFormat);
    }
    return fetchSearch(params, auth, {searchType: "echains"});
};
